import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { pages } from '../pages';
import { Layouts } from '../utils/enums';

interface RouteConfig {
  layout: Layouts;
  path: string;
  exact: boolean;
  component: React.ComponentType;
}

const Landing = () => {
  const getRoutes = (defaultRoutes: RouteConfig[]) =>
    defaultRoutes.map(prop => {
      if (prop.layout === Layouts.Landing) {
        const Component = prop.component;
        return (
          <Route
            key={prop.path}
            path={prop.path}
            element={<Component />}
          />
        );
      }
      return null;
    });

  return <Routes>{getRoutes(pages)}</Routes>;
};

export default Landing;