import React from 'react';
import { Modal, Button, Input, Select, Checkbox, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';

type ModalProps = {
    open: boolean,
    setVisible: Function,
    setDeliverable: Function,
    empId: Number
    approved: boolean
    kpi: any
}

const SurveyModal = ({ open, setVisible, setDeliverable, empId, approved, kpi }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [kpiTitle, setKpiTitle] = React.useState('');
    const [kpiDescription, setKpiDescription] = React.useState('');
    const [localSchedules, setLocalSchedules] = React.useState([])
    const [reviewScheduleId, setReviewScheduleId] = React.useState(null);
    const { authKey } = useAuthContext();
    const { globalUserEmp, globalOrg } = useReviewContext();

    const { Option } = Select
    const { TextArea } = Input;

    React.useEffect(() => {
        if (kpi !== null) {
            setKpiTitle(kpi.title)
            setReviewScheduleId(kpi.reviewScheduleId)
            setKpiDescription(kpi.description)

        }
        else {
            setKpiTitle('')
            setReviewScheduleId(null)
        }
    }, [kpi])

    React.useEffect(() => {
        if (globalUserEmp) {
            APP_URL.get(`reviewschedule/org/${globalOrg.id}`, {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then((res) => {
                setLocalSchedules(res.data)
            }).catch(err => {
                console.log(err.message);
            })
        }
    }, [globalUserEmp])


    const postSurvey = () => {
        setConfirmLoading(true);

        let kpiData = {
            title: kpiTitle,
            reviewScheduleId: reviewScheduleId,
            employeeId: empId,
            creatorId: globalUserEmp.id,
            approved: approved,
            description: kpiDescription
        }

        if (reviewScheduleId === null) {
            message.warning("Please select a Review Schedule")
        }
        else if (kpiTitle.length < 1) {
            message.warning("KPI cannot be empty")
        }
        else {
            APP_URL.post("delivarable", kpiData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDeliverable(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("KPI added successfully")
                setKpiTitle("")

            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the survey")
                console.log(err.message)
            })
        }
    }

    const putSurvey = () => {
        setConfirmLoading(true);

        kpi.title = kpiTitle
        kpi.reviewScheduleId = reviewScheduleId
        kpi.description = kpiDescription

        if (reviewScheduleId === null) {
            message.warning("Please select a Review Schedule")
        }
        else if (kpiTitle.length < 1) {
            message.warning("KPI cannot be empty")
        }
        else {
            APP_URL.put(`delivarable/${kpi.id}`, kpi, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDeliverable(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("KPI updated successfully")
                setKpiTitle("")

            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the survey")
                console.log(err.message)
            })
        }
    }

    const handleOk = () => {
        if (kpi === null) {
            postSurvey()
        }
        else {
            putSurvey()
        }

    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const handleScheduleSelect = (val) => {
        setReviewScheduleId(val)
    }

    return (
        <>
            <Modal
                title="Create new KPI"
                open={open}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Survey added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>
                        <div>Review Schedule:</div>
                        <Select onChange={handleScheduleSelect} value={reviewScheduleId} className='modal-select'>
                            {localSchedules.map((rs, i) => (
                                <Option key={i} value={rs.id}><h6 className="role">{rs.title} </h6> </Option>
                            ))}
                        </Select>
                    </div>

                    <div>KPI:</div> <Input value={kpiTitle} 
                    className='modal-data-input' onChange={(val) => 
                    setKpiTitle(val.target.value)} placeholder="Communication"></Input>


                    <div>Description:</div> <TextArea rows={10} value={kpiDescription}
                     className='modal-data-input' onChange={(val) => setKpiDescription(val.target.value)} 
                     placeholder="How well do I communicate with others"></TextArea>
                </div>
            </Modal>
        </>
    )
}

export default SurveyModal;
