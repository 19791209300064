import React from 'react';
import { Drawer, Avatar, Button, Input, Rate, Radio, Select, message } from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import completed_survey from '../../assets/illustrations/completed-survey.svg';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import { FaRegCircle } from 'react-icons/fa';
import { HiCheckCircle } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Spinners } from '../../components'

import './style.less'

type DrawerProps = {
    open: boolean
    setVisible: Function,
    reviewsList: any[]
    selectedReview: any,
}
//Close button should be more visible

const ReviewDrawer = ({ open, setVisible, reviewsList, selectedReview }: DrawerProps) => {
    const [clickedItemInx, setClickedItemInx] = React.useState(0)
    const [survey, setSurvey] = React.useState(null);
    const [clickedReview, setClickedReview] = React.useState(null);
    const [radioValue, setRadioValue] = React.useState([]);
    const [textKey, setTextKey] = React.useState(Math.random());
    const [textKeyDel, setTextKeyDel] = React.useState(Math.random());
    const [rateKey, setRateKey] = React.useState(Math.random());
    const [rateKeyDel, setRateKeyDel] = React.useState(Math.random());
    const [selectKey, setSelectKey] = React.useState(Math.random());
    const [radioKey, setRadioKey] = React.useState(Math.random());
    // const [reviewListDisplay, setReviewListDisplay] = React.useState([]);
    const [roles, setRoles] = React.useState([]);
    const [deliverables, setDeliverables] = React.useState([]);
    const [deliverableResponses, setDeliverableResponses] = React.useState({});
    const [responseData, setResponseData] = React.useState({});
    const [currentUserEmployee, setCurrentUserEmployee] = React.useState(null);
    const { authKey, currentUser } = useAuthContext();
    const { setGlobalPendingReview } = useReviewContext();
    const [isPosting, setIsPosting] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [canReviewDel, setCanReviewDel] = React.useState(false);
    const [loadingText, setLoadingText] = React.useState('');
    const { globalUserEmp } = useReviewContext();


    const { TextArea } = Input;
    const { Option } = Select;

    const onClose = () => {
        setVisible(false)
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 767 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const getDeliverables = (empId) => {
        setLoading(true)
        setLoadingText("Getting KPIs")
        setDeliverables([])
        APP_URL.get(`delivarable/employee/${empId}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(delivRes => {
                // console.log(delivRes)
                let filteredDeliverables = delivRes.data.filter((del) => del.reviewScheduleId === survey?.reviewScheduleId && del.approved === true)
                // console.log(filteredDeliverables)
                console.log("del: ",filteredDeliverables)
                filteredDeliverables.map((del) => {
                    
                    let tempDel = { questionContent: `{\"title\":\"Rate how well {employee} met your expectations for deliverable: '${del.title}' \"}`, "questionTypeId": 2, delId: del.id }
                    setDeliverables((dels) => [...dels, tempDel])
                })

            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setLoading(false)
            })
    }


    const handleCardSelect = (inx: number, rev: any) => {
        if (rev.completed === true) {

            message.info("You have already completed this review!")

        } else {

            clearMap()
            setRateKey(Math.random())
            setRateKeyDel(Math.random())
            setSelectKey(Math.random())
            setRadioKey(Math.random())
            setTextKey(Math.random())
            setTextKeyDel(Math.random())
            // setResponseData({})
            setClickedItemInx(inx)
            setClickedReview(rev)
        }

    }

    const clearMap = () => {
        let _responseData = { ...responseData }
        for (var variableKey in _responseData) {
            if (_responseData.hasOwnProperty(variableKey)) {
                delete _responseData[variableKey];
            }
        }
        // console.log(_responseData)
        setResponseData(_responseData)
    }

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstName + ' ' + emp.lastName}&background=59394A&color=fff&size=256` }
    }

    const parseString = (questionTitle: string) => {
        return questionTitle.replace(/{employee}/g, clickedReview.firstName).replace(/{manager}/g, clickedReview.firstName)
    }

    const getSurvey = (review) => {
        setLoading(true)
        setLoadingText("Getting Questions")
        APP_URL.get(`survey/${review.surveyId}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(survRes => {
                setSurvey(survRes.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    const getRoles = () => {
        APP_URL.get(`role`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(rolesRes => {
                setRoles(rolesRes.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getCurrentUserEmployee = () => {
        APP_URL.get(`userauth/${currentUser.id}/employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setCurrentUserEmployee(user.data.employees[0])
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }

    const getRoleName = (roleId) => {
        if (roles.length > 0) {
            let role = roles.filter((r) => r.id == roleId)[0];
            if (role) {
                return role.roleName;
            }
            else {
                return 'N/A'
            }
        }
        return ('N/A')
    }


    const respondToQuestion = () => {
        var keys = Object.keys(responseData);
        var values = keys.map(function (v) { return responseData[v]; });

        var delKeys = Object.keys(deliverableResponses);
        var delValues = delKeys.map(function (v) { return deliverableResponses[v]; });

        let questionsWithoutHeaders: any[] = survey.questions.filter(q => q.questionTypeId !== 5)
        // console.log(values, questionsWithoutHeaders)
        if (values.length !== questionsWithoutHeaders.length) {
            message.error("please answer every question on the survey!")
        }
        else if (delValues.length !== deliverables.length && canReviewDel) {
            console.log(delValues, deliverables)
            message.error("please rate every deliverable on the survey!")
        } else {
            let surveyResponse = null
            let reviewerId, reviewedId;

            if (clickedReview.formType === 'manager') {
                reviewerId = globalUserEmp.id
                reviewedId = clickedReview.reviewedId
                surveyResponse = {
                    managerId: clickedReview.reviewedId, reportId: globalUserEmp.id,
                    reviewerId: reviewerId,
                    reviewedId: reviewedId,
                    surveyId: survey.id, responseJson: JSON.stringify(values),
                    reviewScheduleId: survey.reviewScheduleId,
                    orgId: globalUserEmp.orgId,
                    createdBy: `${globalUserEmp.firstname} ${globalUserEmp.lastname}`
                }
            }

            else if (clickedReview.formType === 'self') {
                reviewerId = globalUserEmp.id
                reviewedId = globalUserEmp.id
                surveyResponse = {
                    managerId: globalUserEmp.id, reportId: globalUserEmp.id,
                    reviewerId: reviewerId,
                    reviewedId: reviewedId,
                    surveyId: survey.id, responseJson: JSON.stringify(values),
                    reviewScheduleId: survey.reviewScheduleId,
                    orgId: globalUserEmp.orgId,
                    createdBy: `${globalUserEmp.firstname} ${globalUserEmp.lastname}`
                }
            }

            else if (clickedReview.formType === 'employee') {
                reviewerId = globalUserEmp.id
                reviewedId = clickedReview.reviewedId
                surveyResponse = {
                    managerId: globalUserEmp.id, reportId: clickedReview.reviewedId,
                    reviewerId: reviewerId,
                    reviewedId: reviewedId,
                    surveyId: survey.id, responseJson: JSON.stringify(values),
                    reviewScheduleId: survey.reviewScheduleId,
                    orgId: globalUserEmp.orgId,
                    createdBy: `${globalUserEmp.firstname} ${globalUserEmp.lastname}`
                }
            }

            // console.log(surveyResponse)
            const hide = message.loading(`Adding your response`, 0)
            setIsPosting(true);
            console.log(surveyResponse)
            APP_URL.post("surveyresponse", surveyResponse, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                APP_URL.post("deliverableResponse/batch", delValues, {
                    headers:
                        { "Authorization": `Basic ${authKey}` }
                }).then(resDel => {
                    message.success("Response recorded successfully")
                    clearMap()
                    setRateKey(Math.random())
                    setRateKeyDel(Math.random())
                    setSelectKey(Math.random())
                    setRadioKey(Math.random())
                    setTextKey(Math.random())
                    setTextKeyDel(Math.random())
                    reviewsList[clickedItemInx].completed = true
                    setGlobalPendingReview(reviewsList)
                    let oldClickedItemInx = clickedItemInx
                    if (oldClickedItemInx < reviewsList.length - 1) {

                        setClickedItemInx(oldClickedItemInx + 1)
                        setClickedReview(reviewsList[oldClickedItemInx + 1])

                        reviewsList.splice(oldClickedItemInx)
                        setIsPosting(false);
                    } else if (reviewsList.length > 1) {
                        setClickedItemInx(0)
                        setClickedReview(reviewsList[0])

                        reviewsList.splice(oldClickedItemInx)
                        setIsPosting(false);
                    } else {
                        //completed
                    }
                    hide()
                }).catch((err: AxiosError) => {
                    hide()
                    setIsPosting(false);
                    console.log(err.message)
                    message.error("There was an error adding your response")
                })
                addQuestionResponses(values, survey, reviewerId, reviewedId)
            })

        }
    }

    const addQuestionResponses = (responseArray: any[], survey, reviewerId, reviewedId) => {
        let questionResponseObj = []
        setIsPosting(true);

        responseArray.map((response) => {
            let qResponse = {}
            qResponse['reviewerId'] = reviewerId
            qResponse['reviewedId'] = reviewedId
            qResponse['surveyId'] = survey.id
            qResponse["reviewScheduleId"] = survey.reviewScheduleId
            qResponse["orgId"] = globalUserEmp.orgId
            qResponse['questionId'] = response.questionId
            qResponse['questionTitle'] = response.questionTitle
            qResponse['answers'] = JSON.stringify(response.questionResponse)
            qResponse['isGlow'] = JSON.stringify(response.isGlow)
            qResponse['isGrow'] = JSON.stringify(response.isGrow)
            qResponse['isOverallRating'] = JSON.stringify(response.isOverallRating)

            questionResponseObj.push(qResponse)
        })
        // console.log(questionResponseObj)
        APP_URL.post("questionresponse/batch", questionResponseObj, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).catch((err: AxiosError) => {
            console.log(err.message)
        }).finally(() => {
            setIsPosting(false);
        })
    }

    const moveItemToFront = (item: any, list: any[]) => {
        list = list.filter(i => i.id !== item.id)
        list.unshift(item)
        return list
    }

    React.useEffect(() => {
        if (currentUser) {
            // getCurrentUserEmployee()
            getRoles()
        }

        if (selectedReview) {
            console.log(selectedReview)
            // let rList = moveItemToFront(selectedReview, reviewsList)
            setClickedItemInx(reviewsList.map(x => x.id).indexOf(selectedReview.id))
            // setReviewListDisplay(rList)
            setClickedReview(selectedReview)
        }
    }, [authKey, selectedReview, currentUser])

    React.useEffect(() => {
        if (clickedReview) {
            // console.log(clickedEmp)
            getSurvey(clickedReview)

            //getSurveyResponses()
        }
    }, [clickedReview])

    React.useEffect(() => {
        if (survey !== null) {
            setDeliverables([])
            getDeliverables(clickedReview.reviewedId)
            console.log(clickedReview)
            if (clickedReview.managerId === globalUserEmp.id || clickedReview.reviewedId === globalUserEmp.id) {
                setCanReviewDel(true)
                console.log(canReviewDel)
            } else {
                console.log(canReviewDel)
            }
        }
    }, [survey])

    const onRadioChange = (e, q) => {
        // console.log('radio checked', e.target.value);

        setRadioValue(e.target.value)
        let responseArr = []
        responseArr.push(e.target.value)
        let tempResponse = { ...responseData }
        tempResponse[Number(q.id)] = {
            questionTitle: String(JSON.parse(q.questionContent).title),
            questionId: q.id,
            order: q.orderIndex,
            questionTypeId: q.questionTypeId,
            questionResponse: JSON.stringify(responseArr),
            isGrow: q.isGrow,
            isGlow: q.isGlow,
            isOverallRating: q.isOverallRating
        }
        // console.log(tempResponse)

        setResponseData(tempResponse)
    };


    const onSelectChange = (e, q) => {
        let tempResponse = { ...responseData }
        tempResponse[Number(q.id)] = {
            questionTitle: String(JSON.parse(q.questionContent).title),
            questionId: q.id,
            order: q.orderIndex,
            questionTypeId: q.questionTypeId,
            questionResponse: e,
            isGrow: q.isGrow,
            isGlow: q.isGlow,
            isOverallRating: q.isOverallRating
        }
        // console.log(tempResponse)
        setResponseData(tempResponse)
    }

    const onGeneralChange = (e, q) => {
        let tempResponse = { ...responseData }
        let responseArr = []
        responseArr.push(e.target.value)
        tempResponse[Number(q.id)] = {
            questionTitle: String(JSON.parse(q.questionContent).title),
            questionId: q.id,
            order: q.orderIndex,
            questionTypeId: q.questionTypeId,
            questionResponse: responseArr,
            isGrow: q.isGrow,
            isGlow: q.isGlow,
            isOverallRating: q.isOverallRating
        }
        // console.log(tempResponse)
        setResponseData(tempResponse)
    }

    const onRateChange = (rating, q) => {
        let tempResponse = { ...responseData }
        let responseArr = []
        responseArr.push(rating)
        tempResponse[Number(q.id)] = {
            questionTitle: String(JSON.parse(q.questionContent).title),
            questionId: q.id, order: q.orderIndex,
            questionTypeId: q.questionTypeId,
            questionResponse: responseArr,
            isGrow: q.isGrow,
            isGlow: q.isGlow,
            isOverallRating: q.isOverallRating
        }
        //console.log(tempResponse)
        setResponseData(tempResponse)
    }

    const onRateDeliverable = (rating, question) => {
        let tempResponse = { ...deliverableResponses }
        tempResponse[Number(question.delId)] = { rating: rating, deliverableId: question.delId, reviewedId: clickedReview.reviewedId }
        console.log(tempResponse)
        setDeliverableResponses(tempResponse)
    }
    const onJustifyDeliverable = (answer, question) => {
        let tempResponse = { ...deliverableResponses }
        if (tempResponse[Number(question.delId)]) {
            tempResponse[Number(question.delId)].justification = answer;
        }
        console.log("rating justification: ", tempResponse)
        setDeliverableResponses(tempResponse)
    }

    const renderElement = (q: { questionTypeId: number; questionContent: string; }, isDeliverable: boolean) => {

        if (q.questionTypeId == 1) {
            return <TextArea key={textKey} onChange={(e) => onGeneralChange(e, q)} rows={3} />
        }
        else if (q.questionTypeId == 2) {
            if (isDeliverable) {
                return <Rate key={rateKey} onChange={(rating) => onRateDeliverable(rating, q)} className='q-rate' />

            } else {
                return <Rate key={rateKeyDel} onChange={(rating) => onRateChange(rating, q)} className='q-rate' />
            }
        }
        else if (q.questionTypeId == 3) {
            return (
                <Radio.Group key={radioKey} onChange={(e) => onRadioChange(e, q)} className='multi-choice' >
                    {JSON.parse(q.questionContent).items?.map((m) => (
                        <Radio value={m}>{m}</Radio>
                    ))}
                </Radio.Group>
            )
        }
        else if (q.questionTypeId == 4) {
            return (
                <>
                    <Select key={selectKey} mode="tags" size={"large"} onChange={e => onSelectChange(e, q)} style={{ width: '100%', height: "60px" }} placeholder="Select an item or type something new and press enter"   >
                        {JSON.parse(q.questionContent).items?.map((m) => (
                            <Option value={m}>{m}</Option>
                        ))}
                    </Select>
                </>
            )
        }
        else if (q.questionTypeId == 5) {
            return <span className='q-header'></span>
        }
        else {
            return <span></span>
        }
    }

    return (
        <>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                open={open}
                width={'100%'}
                className='review-drawer'
                closeIcon={<AiFillCloseCircle style={{ fontSize: '4.2rem', color: '#1E7D79' }} />}
            >
                <Spinners.CustomSpinner tip={loadingText} spinning={loading}>
                    <div className='review-drawer-root' >
                        <div className='rev-card-row'>
                            <Carousel
                                infinite
                                className='carousel'
                                responsive={responsive}
                                removeArrowOnDeviceType={["tablet", "mobile", "notebook"]}
                            >
                                {reviewsList.map((report, inx) => (
                                    <div key={inx} onClick={() => handleCardSelect(inx, report)} className={`review-card ${clickedItemInx === inx ? "selected-card" : ""} ${report.completed === true ? "completed-rev" : ""}`}>
                                        <div className='rev-card-top'>
                                            {report.completed === true ?
                                                <><HiCheckCircle className='completed-check' /> <span className='completed-text'>Completed</span></>
                                                :
                                                <FaRegCircle className='non-complete' />
                                            }
                                        </div>
                                        <div className='rev-card-profile'>
                                            <Avatar className={'rev-card-avatar'} src={getDisplayPic(report)} size={'large'} />
                                            <div className='rev-text-col'>
                                                <div className={`rev-card-name ${clickedItemInx === inx ? "selected-rev-card-name" : ""}`}>{report.firstName} {report.lastName}</div>
                                                <div className={`rev-card-role ${clickedItemInx === inx ? "selected-rev-card-role" : ""}`}>{getRoleName(report.roleId)} </div>
                                                <div className={`rev-card-role ${clickedItemInx === inx ? "selected-rev-card-role" : ""}`}>{report.email}</div>
                                            </div>
                                        </div>
                                        <div className='rev-card-bottom'>
                                            {report.formType === 'employee' && <div className='rev-card-tag'>Direct Report</div>}
                                            {report.formType === 'manager' && <div className='rev-card-tag'>Manager</div>}
                                            {report.formType === 'self' && <div className='rev-card-tag'>Self Review</div>}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        {survey &&
                            <>
                                {deliverables.length > 0 || !clickedReview.managerId ?
                                    <div className='survey-droppable-area survey-response-area'>
                                        {reviewsList.length > 0 ?
                                            <>
                                                <div key={-200} className='welcome-closing-messsage' >
                                                    <span className='opening-msg'>
                                                        {survey.welcomeMessage}
                                                    </span>
                                                </div>
                                                {survey.questions.map((q, i) => (
                                                    <div key={i} className='question' >
                                                        <div className='question-top'>
                                                            {q.questionTypeId === 5 ?
                                                                <span className='question-title header' >{parseString(String(JSON.parse(q.questionContent).title))}</span>
                                                                :
                                                                <span className='question-title' >{parseString(String(JSON.parse(q.questionContent).title))}</span>
                                                            }
                                                            <span className='question-controls'></span>
                                                        </div>
                                                        <div className="question-element">
                                                            {renderElement(q, false)}
                                                        </div>
                                                    </div>
                                                ))}
                                                {canReviewDel && deliverables.length > 0 &&
                                                    <>
                                                        <div className='question-top'>
                                                            <span className='question-title header' >Deliverables: </span>
                                                        </div>
                                                        {deliverables.map((q, i) => (
                                                            <>
                                                                <div key={i} className='question' >
                                                                    <div className='question-top'>
                                                                        {q.questionTypeId === 5 ?
                                                                            <span className='question-title header' >{parseString(String(JSON.parse(q.questionContent).title))}</span>
                                                                            :
                                                                            <span className='question-title' >{parseString(String(JSON.parse(q.questionContent).title))}</span>
                                                                        }
                                                                        <span className='question-controls'></span>
                                                                    </div>
                                                                    <div className="question-element">
                                                                        {renderElement(q, true)}
                                                                    </div>
                                                                </div>
                                                                <div key={i} className='question' >
                                                                    <div className='question-top'>
                                                                        <span className='question-title' >Justify the above rating</span>
                                                                        <span className='question-controls'></span>
                                                                    </div>
                                                                    <TextArea key={textKeyDel} onChange={(e) => onJustifyDeliverable(e.target.value, q)} rows={3} />
                                                                </div>

                                                            </>
                                                        ))}
                                                    </>
                                                }


                                                <div key={-201} className='welcome-closing-messsage' >
                                                    <span className='opening-msg'>
                                                        {survey.closingMessage}
                                                    </span>
                                                </div>
                                                <Button disabled={isPosting} onClick={() => respondToQuestion()} className='next-survey-btn'>Submit</Button>
                                            </> :
                                            <div className='survey-completed-view'>
                                                <span className='thanks-text'>You have completed all your surveys!</span>
                                                <img className='completed-img' src={completed_survey} />
                                            </div>
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className='no-del-view'>
                                            <span className='thanks-text'>{clickedReview.firstName} needs to have at least one approved KPI to activate this survey</span>
                                            <img className='no-del-img' style={{ width: "300px" }} src={completed_survey} />
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </Spinners.CustomSpinner>
            </Drawer>
        </>
    )
}

export default ReviewDrawer;