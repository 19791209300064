import React from 'react';
import users_icon from '../../../assets/icons/users-icon.svg'
import doc_icon from '../../../assets/icons/doc-icon.svg'
import './style.less';
import { Bar } from 'react-chartjs-2';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { APP_URL } from '../../../config/axios'
import { AxiosError } from 'axios';
import { Avatar, List, Button, Table, Input, Select, Checkbox } from 'antd';
import moment from 'moment';
import { Drawers } from '../../../components'
import { AiOutlineSearch } from 'react-icons/ai';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';

// import { IRecentSurvey } from './recent-survey.interface';

export const Home = () => {
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState([]);
  const [managers, setManagers] = React.useState([0]);
  const [activeSurveys, setActiveSurveys] = React.useState([]);
  const [surveyResponses, setSurveyResponses] = React.useState([]);
  const [surveys, setSurveys] = React.useState([]);
  const [role, setRole] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [uniqueCount, setUniqueCount] = React.useState([]);
  const [recentlyReviewedSurveys, setRecentlyReviewedSurveys] = React.useState([]);
  const [empDrawerVisible, setEmpDrawerVisible] = React.useState(false)
  const { authKey, currentUser } = useAuthContext();
  const { globalOrg } = useReviewContext();
  const [reviewSchedule, setReviewSchedule] = React.useState([]);
  const [completedStatuses, setCompletedStatuses] = React.useState([]);

  const right: FixedType = 'right';


  const getDisplayPic = (emp) => {
    if (emp.profilePic !== null) { return String(emp.profilePic); }
    else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
  }


  const getSurveyResponses = () => {
    APP_URL.get(`surveyresponse`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(surveys => {
        setSurveyResponses(surveys.data);
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }


  const getSurveys = () => {
    APP_URL.get(`survey`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(surveys => {
        setSurveys(surveys.data);
        getActiveReviewSchdedules(surveys.data)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }
  const getUserAuths = () => {
    APP_URL.get(`userauth`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(res => {
        let userAuths = res.data.map(user => {
          user.createdAt = moment(user.createdAt).format('DD MMM YYYY')
          return user
        })

        // console.log(userAuths)

        let uniqueLabels = Array.from(new Set(userAuths.map(user => user.createdAt)))
        setLabels(uniqueLabels)
        let uniqueCount = []
        uniqueLabels.map((date) => {
          var count = userAuths.reduce(function (n, val) {
            return n + (val.createdAt === date);
          }, 0);
          uniqueCount.push(count)
        })
        setUniqueCount(uniqueCount)
        // console.log(uniqueCount)
        // console.log(uniqueLabels)

      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }


  const getActiveReviewSchdedules = (surveyList) => {
    APP_URL.get(`reviewschedule/org/${globalOrg.id}`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(res => {
        setReviewSchedule(res.data);
        let activeReviews = res.data.filter(rs => rs.active == true);
        setActiveSurveys([])
        activeReviews.map(ar => {
          let as = surveyList.filter(surv => surv.reviewScheduleId == ar.id)
          setActiveSurveys(activeSurveys => [...activeSurveys, ...as])
        })

      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  const getRoles = () => {
    APP_URL.get(`role/org/${globalOrg.id}`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(role => {
        setRole(role.data);
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  };

  const getEmployees = () => {
    APP_URL.get(`employee/org/${globalOrg.id}`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(user => {
        setEmployees(user.data)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })

  }

  React.useEffect(() => {
    if (currentUser) {
      getSurveyResponses();
      getSurveys();
      getRoles();
      getUserAuths()

    }
  }, [currentUser]);


  React.useEffect(() => {
    getEmployees()

  }, [authKey])

  React.useEffect(() => {
    if (employees.length > 0) {
      let _managers = Array.from(new Set(employees.map(item => item.managerId)));
      setManagers(_managers)

      if (surveyResponses.length > 0 && surveys.length > 0 && role.length > 0) {
        // add reviwer, survey and role info
        let _recentlyReviedSurveys = surveyResponses.map((survey) => {
          survey['reviwerInfo'] = employees.find((x) => survey.reviewerId == x.id);
          survey['surveyInfo'] = surveys.find((x) => survey.surveyId == x.id);
          survey['role'] = role.find(x => x.id === survey.reviwerInfo?.roleId);
          return survey;
        });

        // sort by recent date
        _recentlyReviedSurveys = _recentlyReviedSurveys.sort((a, b) => {
          const dateA = new Date(Date.parse(a.createAt));
          const dateB = new Date(Date.parse(b.createAt));
          return dateB.valueOf() - dateA.valueOf();
        })

        setRecentlyReviewedSurveys(_recentlyReviedSurveys);
      }
    }

  }, [employees, surveyResponses, surveys, role])



  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Sign in Activity',
        backgroundColor: '#1D3948',
        borderColor: '#1D3948',
        borderWidth: 1,
        hoverBackgroundColor: '#234b91',
        hoverBorderColor: '#234b91',
        data: uniqueCount
      }
    ]
  };

  return <main id="admin-home">
    <div className='flex-row'>
      <div className='admin-left'>
        <div className='admin-card-flex'>
          <div className='admin-card'>
            <div className='card-text'>
              <div className='admin-card-head'>Employees</div>
              <div className='admin-card-num'>{employees.length}</div>
            </div>
            <img alt='icon' src={users_icon} />
          </div>
          <div className='admin-card'>
            <div className='card-text'>
              <div className='admin-card-head'>Managers</div>
              <div className='admin-card-num'>{managers.length - 1}</div>
            </div>
            <img alt='icon' src={users_icon} />
          </div>
          <div className='admin-card'>
            <div className='card-text'>
              <div className='admin-card-head'>User Types</div>
              <div className='admin-card-num'>2</div>
            </div>
            <img alt='icon' src={users_icon} />
          </div>
        </div>
        <div className='admin-graph-container' >
          <div className='graph-head'>
            <span className='graph-title'>Platform Activity (%)</span>
            <span className='graph-dropdown'>RECENT SIGN UPS</span>
          </div>
          <div style={{ width: '95%', margin: '20px auto' }} >
            <Bar
              data={data}
              height={350}
              options={{
                maintainAspectRatio: false,
                scales: {
                  yAxes: [{
                    ticks: {
                      beginAtZero: true,
                      stepSize: 1
                    }
                  }]
                }
              }}
            />
          </div>
        </div>

      </div>
      <div className='admin-right'>
        <div className='admin-card' style={{ width: '100%', height: '190px', alignItems: 'normal' }}>
          <div className='card-text'>
            <div className='admin-card-head'>Active Surveys</div>
            <div className='admin-card-num'>{activeSurveys.length}</div>
          </div>
          <img className='doc-icon' alt='icon' src={doc_icon} />
        </div>
        <div className='recently-logged-contain'>
          <div className='recently-logged-head'>
            <span className='recent-title'>Recently Completed Surveys</span>
            <span className='graph-dropdown'>Newest</span>
          </div>
          <List
            itemLayout="vertical"
            dataSource={recentlyReviewedSurveys}
            loading={recentlyReviewedSurveys.length === 0}
            renderItem={item => (
              <List.Item
                style={{ paddingLeft: 20, paddingRight: 20 }}
                extra={<span className='recent-list-time'>{moment(item.createdAt).fromNow()}</span>}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.reviwerInfo && item.reviwerInfo.profilePic} style={{ height: 40, width: 40 }} />}
                  title={
                    <div>
                      <div className='recent-list-title'>{item.reviwerInfo && item.reviwerInfo.firstname} {item.reviwerInfo && item.reviwerInfo.lastname}</div>
                      <div className='recent-list-role'>{item.role && item.role.roleName}</div>
                    </div>}
                  description={<div className='recent-list-survey-name'>{item.surveyInfo?.title}</div>}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
    <Drawers.EmployeeReviewDrawer open={empDrawerVisible} employee={employee} setVisible={setEmpDrawerVisible} />
  </main >;
};
export declare type FixedType = 'left' | 'center' | 'right';
