import React, { useState } from 'react';
import './style.less';
import { Avatar, Table, Button, Typography, Card } from 'antd'
import { APP_URL } from '../../../config/axios'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { Drawers, Spinners } from '../../../components'

const { Title } = Typography;

const EmployeeDepartments = ({ employeeDepartments }) => {
  return (
    <div className="p-4">
      {employeeDepartments.map((department) => (
        <Card
          key={department.departmentId}
          className="mb-6 shadow-md"
          style={{ borderRadius: "10px", marginBottom: "2rem" }}
        >
          <Title level={3} className="text-lg sm:text-xl md:text-2xl">
            {department.departmentName}
          </Title>
          <p className="text-gray-600 text-sm sm:text-base">{department.departmentDescription}</p>

          <div className="overflow-x-auto">
            <Table
              dataSource={department.employees}
              rowKey="employeeId"
              pagination={false}
              bordered
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on small screens
            >
              <Table.Column title="ID" dataIndex="employeeId" key="employeeId" />
              <Table.Column title="First Name" dataIndex="firstname" key="firstname" />
              <Table.Column title="Last Name" dataIndex="lastname" key="lastname" />
              <Table.Column title="Email" dataIndex="email" key="email" />
            </Table>
          </div>
        </Card>
      ))}
    </div>
  );
};

export const Team = () => {

  const [usersList, setUsersList] = React.useState([])
  const [userEmp, setUserEmp] = React.useState(null)
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const [currentEmployee, setCurrentEmployee] = React.useState(null)
  const [selectedEmployee, setSelectedEmployee] = React.useState(null)
  const [roles, setRoles] = React.useState([])
  const [userDepartments, setUserDepartments] = React.useState([])
  const [department, setDepartment] = React.useState(null)
  const [employeeDepartments, setEmployeeDepartments] = React.useState([])
  const { authKey, currentUser } = useAuthContext();
  const { globalOrg, globalSchedules, globalUserEmp } = useReviewContext();

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  }

  const getDisplayPic = (emp) => {
    if (emp.profilePic !== null) { return String(emp.profilePic); }
    else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
  }

  const isMobile = () => width <= 640;


  // const getDepartment = (emp) => {
  //   APP_URL.get(`department/${emp?.deptId}`, {
  //     headers:
  //       { "Authorization": `Basic ${authKey}` }
  //   }).then(res => {
  //     setDepartment(res.data)
  //   }).catch((err: AxiosError) => {
  //     if (err.response) {
  //       console.log(err.message)
  //     }
  //   })
  // }
  const getDepartmentMembers = (emp) => {
    APP_URL.get(`department/by_employee/${emp?.id}`, {
      headers:
        { "Authorization": `Basic ${authKey}` }
    }).then(res => {
      setEmployeeDepartments(res.data);
      console.log("departments: " ,res.data)
    }).catch((err: AxiosError) => {
      if (err.response) {
        console.log(err.message)
      }
    })
  }


  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  React.useEffect(() => {
    if (userEmp) {
      APP_URL.get(`employee/${userEmp?.id}`, {
        headers:
          { "Authorization": `Basic ${authKey}` }
      }).then(res2 => {
        setCurrentEmployee(res2.data)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
    }
  }, [userEmp, authKey])

  React.useEffect(() => {
    if (currentUser && globalOrg && authKey && globalUserEmp) {

      setUserEmp(globalUserEmp)
        
      APP_URL.get(`employee/org/${globalOrg.id}`,
        {
          headers:
            { "Authorization": `Basic ${authKey}` }
        }).then(res => {
          if (res.status === 200) {
            setUsersList(res.data)
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            console.log(err.message)
          }
        })

      APP_URL.get(`role/org/${globalOrg.id}`,
        {
          headers:
            { "Authorization": `Basic ${authKey}` }
        }).then(res => {
          if (res.status === 200) {
            setRoles(res.data)
          }
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            console.log(err.message)
          }
        })
    }
  }, [authKey, currentUser, globalOrg, globalUserEmp, globalSchedules])

  React.useEffect(() => {
    if (userEmp) {
      getDepartmentMembers(userEmp)
    }
    if (usersList.length > 1 && userEmp) {
      setUserDepartments(usersList.filter(ul => ul.deptId === userEmp.deptId))
    }
  }, [usersList, userEmp])

  const canReviewTableData = [
    {
      title: <div className="user-table-head">Name</div>,
      dataIndex: 'firstname',
      width: 250,
      key: 'firstname',
      render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
    },
    {
      title: <div className="user-table-head">Email</div>,
      dataIndex: 'email',
      width: 300,
      key: 'email',
    },
    {
      title: <div className="user-table-head">Role</div>,
      dataIndex: 'roleId',
      width: 250,

      key: 'roleId',
      render: (text) => <span>{String(roles.length > 0 && roles.filter(r => r.id === text)[0]?.roleName)}</span>
    },

    {
      title: <div className="user-table-head">Actions</div>,
      dataIndex: 'deptId',
      width: 150,
      // fixed: right,
      key: 'deptId',
      render: (active, record) =>
        <Button onClick={() => showDrawer(record)} className="update-btn">Edit KPIs</Button>
    },
  ];

  const activeUsersColumns = [
    {
      title: <div className="user-table-head">Name</div>,
      dataIndex: 'firstname',
      width: 250,
      key: 'firstname',
      ellipsis: true,
      sorter: {
        compare: (a, b) => a.firstname.localeCompare(b.firstname),
        multiple: 1
      },
      render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
    },
    {
      title: <div className="user-table-head">Email</div>,
      dataIndex: 'email',
      width: 300,
      key: 'email',
    },
    {
      title: <div className="user-table-head">Role</div>,
      dataIndex: 'roleId',
      key: 'roleId',
      render: (text) => <span>{String(roles.length > 0 && roles.filter(r => r.id === text)[0]?.roleName)}</span>
    },
  ]

  const mobileActiveUsersColumns = [
    {
      className: 'td-mobile',
      dataIndex: 'email',
      key: 'email',
      width: 86,
      render: (text, record) =>
        <div className='mobile-teams-table-row'>
          <Avatar style={{ width: 50, height: 50, marginTop: 18, marginLeft: 13 }} src={`https://ui-avatars.com/api/?name=${text + ' ' + record.lastname}&background=59394A&color=fff`} />
        </div>
    },
    {
      className: 'td-mobile',
      dataIndex: 'roleId',
      key: 'roleId',
      ellipsis: true,
      sorter: {
        compare: (a, b) => a.firstname.localeCompare(b.firstname),
        multiple: 1
      },
      render: (text, record) => (
        <div className='mobile-teams-table-row mobile-teams-table-data-column'>
          <div className='mobile-teams-table-name'>{record.firstname} {record.lastname}</div>
          <div>
            <span className='mobile-teams-table-details-title'>Email:</span>
            <span className='mobile-teams-table-details-data'>{record.email}</span>
          </div>
          <div>
            <span className='mobile-teams-table-details-title'>Role:</span>
            <span className='mobile-teams-table-details-data'>{String(roles.length > 0 && roles.filter(r => r.id === text)[0]?.roleName)}</span>
          </div>
        </div >
      )
    },
  ]

  const showDrawer = (emp) => {
    setSelectedEmployee(emp)
    setDrawerVisible(true)
  }


  return <main id="team-root">
    {isMobile() ?
      <div className='team-mobile-header '>Team Management</div>
      :
      <div className='pending-rev'>Your Team Members</div>
    }

    {userEmp &&
      <div className='team-tables'>
        
        <div className='rev-table-contain' >
          {/* <div className='team-table-label'>{department?.deptName} team members</div>
          <Table className='review-table ' rowKey="id" columns={isMobile() ? mobileActiveUsersColumns : activeUsersColumns} dataSource={userDepartments} /> */}
          <EmployeeDepartments employeeDepartments={employeeDepartments} />
        </div>

        <div className='rev-table-contain' >
          <div className='team-table-label'>Direct Reports</div>
          <Table columns={canReviewTableData} dataSource={currentEmployee?.inverseManager} />
        </div>
      </div>
    }
    <Drawers.ManageDeliverablesDrawer employee={selectedEmployee} open={drawerVisible} setVisible={setDrawerVisible} />

  </main>;
};
