import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import 'antd/dist/antd.less';
import './theme/global.less';
import { AuthProvider } from './context/AuthContext';
import { ReviewProvider } from './context/ReviewsContext';
import { ReportProvider } from './context/ReportContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <GoogleOAuthProvider clientId="948172179633-06sdssdmg3urq7b930f0b7u8re4udghs.apps.googleusercontent.com">
        <ReviewProvider>
          <ReportProvider>
            < Routes />
          </ReportProvider>
        </ReviewProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
