import React from 'react';
import './style.less';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { AxiosError } from 'axios';
import { APP_URL } from '../../../config/axios'
import { Bar } from 'react-chartjs-2';
import { Avatar, Button, Input, Radio, Rate, Checkbox, message, List } from 'antd'
import moment from 'moment';
import { Drawers } from '../../../components';

type Prop = {
  isReportPage?: boolean,
}

export const Reports = ({ isReportPage = true }: Prop) => {
  const [surveyResponses, setSurveyResponses] = React.useState([])
  const [allSurveyResponses, setAllSurveyResponses] = React.useState([])
  const [displaySurveyResponses, setDisplaySurveyResponses] = React.useState([])
  const [surveys, setSurveys] = React.useState([])
  const [radioValue, setRadioValue] = React.useState(null)
  const [currentSelfReview, setCurrentSelfReview] = React.useState(null)
  const [otherSelfReview, setOtherSelfReview] = React.useState(null)
  const [selectedResponse, setSelectedResponse] = React.useState(null)
  const [yourReviewOfOther, setYourReviewOfOther] = React.useState(null)
  const { authKey, currentUser } = useAuthContext();
  const { globalUserEmp, setGlobalUserEmp, globalReports, setGlobalReports } = useReviewContext();
  const [employees, setEmployees] = React.useState([]);
  const [yourRatings, setYourRatings] = React.useState([])
  const [otherRatings, setOtherRatings] = React.useState([])
  const [reviewSummaryComment, setReviewSummaryComment] = React.useState(null);
  const [reviewedId, setReviewedId] = React.useState(null);
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const [otherDrawerVisible, setOtherDrawerVisible] = React.useState(false)
  const [questionResponse, setQuestionResponse] = React.useState([]);

  const { TextArea } = Input;

  const parseString = (questionTitle: string) => {
    return questionTitle.replace(/{employee}/g, currentUser.firstName).replace(/{manager}/g, currentUser.firstName)
  }

  const getSurveyResponses = () => {
    APP_URL.get(`userauth/${currentUser.id}/employee`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(user => {
        APP_URL.get(`surveyresponse`,
          {
            headers: { "Authorization": `Basic ${authKey}` }
          }).then(surveys => {
            setGlobalUserEmp(user.data.employees[0])
            setSurveyResponses(surveys.data.filter(x => x.reviewedId === user.data.employees[0].id));
            setAllSurveyResponses(surveys.data)
            // setGlobalReports(surveys.data.filter(x => x.reviewedId === user.data.employees[0].id));
          }).catch((err: AxiosError) => {
            if (err.response) {
              console.log(err.message)
            }
          })
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  const getSurveys = () => {
    APP_URL.get(`survey`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(surveys => {
        setSurveys(surveys.data);
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  const getEmployees = () => {
    APP_URL.get(`employee`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(user => {
        setEmployees(user.data)
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }

  const getQuestionResponses = () => {
    APP_URL.get(`questionResponse`,
      {
        headers: { "Authorization": `Basic ${authKey}` }
      }).then(qs => {
        setQuestionResponse(qs.data);
      }).catch((err: AxiosError) => {
        if (err.response) {
          console.log(err.message)
        }
      })
  }


  const getSelfReview = (survRes) => {

    let _reviewOfYou: any = allSurveyResponses.filter(x => x.reviewScheduleId === survRes.reviewScheduleId && x.reviewedId === globalUserEmp.id && x.reviewerId === survRes.reviewerId)[0]
    console.log("ReviewofYou: ", _reviewOfYou)
    setReviewedId(globalUserEmp.id)
    let reviewOfYouResponse;
    if (_reviewOfYou) {
      if ((Array.isArray(_reviewOfYou.responseJson))) {
        reviewOfYouResponse = _reviewOfYou.responseJson
      } else {
        reviewOfYouResponse = JSON.parse(_reviewOfYou.responseJson)
      }
      reviewOfYouResponse = reviewOfYouResponse.sort((a, b) => (a.order) - (b.order));
      _reviewOfYou.responseJson = reviewOfYouResponse
      // console.log(_reviewOfYou.responseJson)
      _reviewOfYou.reviewerInfo = survRes.reviewerInfo;
      setCurrentSelfReview(_reviewOfYou)
      getReviewComment(survRes)
      // reset ratings
      setYourRatings([])
      setOtherRatings([]);
      setDrawerVisible(true)
    } else {
      message.error(`${survRes.reviewerInfo.firstname} has not yet completed your review`)
    }

  }

  const getOtherReview = (survRes) => {

    // let _otherSelfReview: any = allSurveyResponses.filter(x => x.reviewScheduleId === survRes.reviewScheduleId && x.reviewerId === survRes.reviewerId && x.reviewedId === survRes.reviewerId)[0]
    let _yourReviewOfOther: any = allSurveyResponses.filter(x => x.reviewScheduleId === survRes.reviewScheduleId && x.reviewerId === globalUserEmp.id && x.reviewedId === survRes.reviewerId)[0]
    console.log("yourReview: ", _yourReviewOfOther)
    setReviewedId(survRes.reviewerId)
    let _yourReviewOfOtherResponse;
    if (_yourReviewOfOther) {
      if ((Array.isArray(_yourReviewOfOther.responseJson))) {
        _yourReviewOfOtherResponse = _yourReviewOfOther.responseJson
      } else {
        _yourReviewOfOtherResponse = JSON.parse(_yourReviewOfOther.responseJson)
      }
      _yourReviewOfOtherResponse = _yourReviewOfOtherResponse.sort((a, b) => (a.order) - (b.order));
      _yourReviewOfOther.responseJson = _yourReviewOfOtherResponse
      // console.log(_yourReviewOfOther.responseJson)
      _yourReviewOfOther.reviewerInfo = survRes.reviewerInfo;
      setYourReviewOfOther(_yourReviewOfOther)

      getReviewComment(survRes)
      // reset ratings
      setYourRatings([])
      setOtherRatings([]);


      setOtherDrawerVisible(true)
    } else {
      message.error(`Please complete your review of ${survRes.reviewerInfo.firstname} to view results`)
    }
    // }
    // else {
    //   message.error(`${survRes.reviewerInfo.firstname} needs to complete their self review to view results`)
    // }
  }

  const getReviewComment = (survRes) => {
    let _reviewComment = survRes.reviewComments.filter(x => x.reviewerId === globalUserEmp.id && x.surveyResponseId === survRes.id)

    if (_reviewComment.length === 0) {
      _reviewComment = {
        "comment": null,
        "rating": null,
        "reviewerId": survRes.reviewedId,
        "reviewedId": survRes.reviewerId,
        "surveyResponseId": survRes.id,
      }
      setReviewSummaryComment(_reviewComment);
    } else {
      _reviewComment[0].shouldUpdate = true;
      setReviewSummaryComment(_reviewComment[0]);
    }
  }

  React.useEffect(() => {
    if (currentUser) {

      getSurveyResponses()

      getQuestionResponses()
      getEmployees()
      getSurveys()
    }

  }, [currentUser, authKey])


  React.useEffect(() => {
    if (employees.length > 0 && surveys.length > 0 && surveyResponses.length > 0 && globalUserEmp) {
      // add reviwer, survey and role info
      let _recentlyReviedSurveys = surveyResponses.map((survey) => {
        survey['reviewerInfo'] = employees.find((x) => survey.reviewerId == x.id);
        survey['surveyInfo'] = surveys.find((x) => survey.surveyId == x.id);
        return survey;
      });
      // remove self reviews
      setDisplaySurveyResponses(_recentlyReviedSurveys.filter(x => x.reviewerId !== globalUserEmp.id));
    }

  }, [employees, surveyResponses, globalUserEmp, surveys])

  return (<main id="reports" className={isReportPage ? 'report-page-full': undefined}>

    <div className='con-glows' >Total Reviews: <span className='total-rev'>{displaySurveyResponses.length}</span></div>

    <hr />
    <div className='rev-timeline'>
      {displaySurveyResponses.map((response, inx) => (
        <div className='report-card'>
          <div className='circle'>
            <Avatar src={response.reviewerInfo && response.reviewerInfo.profilePic} style={{ width: '70px', height: '70px' }} />
          </div>
          <div>
            <div className='between-texts'>
              <span className='timeline-text'>{response && moment(response.createAt).format('DD MMMM YYYY')}</span>
              <span className='timeline-text'>{response && moment(response.createAt).format('HH:mm A')}</span>
            </div>
            <hr className='between-line' />
            <div className='between-texts'>
              <span className='timeline-text'>{response.reviewerInfo && response.reviewerInfo.firstname + ' '
                + response.reviewerInfo.lastname}</span>
              <div className='view-btns-col'>
                <Button className='timeline-btn' onClick={() => getSelfReview(response)} >View {response.reviewerInfo.firstname}'s' Review</Button>
                <Button className='timeline-btn' onClick={() => getOtherReview(response)} >View Your review of {response.reviewerInfo.firstname}</Button>
              </div>


            </div>
          </div>
        </div>
      ))}
    </div>
    <hr />


    <Drawers.ChartReportDrawer open={drawerVisible}
      setVisible={setDrawerVisible}
      selectedResponse={currentSelfReview}
      name={currentSelfReview?.reviewerInfo.firstname}
      reviewComment={reviewSummaryComment} inverse={false}
      reviewedId={reviewedId} />
    <Drawers.ChartReportDrawer open={otherDrawerVisible}
      setVisible={setOtherDrawerVisible}
      selectedResponse={yourReviewOfOther}
      name={yourReviewOfOther?.reviewerInfo.firstname}
      reviewComment={reviewSummaryComment} inverse={true}
      reviewedId={reviewedId} />
  </main>);
};
