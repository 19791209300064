import React from 'react';
import { Modal, Button, Input, message, Form,  DatePicker, Select } from 'antd';
import { APP_URL } from '../../config/axios';
import { FormInstance } from 'antd/lib/form';
import './style.less';
import { useReviewContext } from '../../context/ReviewsContext';
import { useAuthContext } from '../../context/AuthContext';


type ModalProps = {
    open: boolean,
    setVisible: Function,
    setSchedule: Function
}

const CreateReviewScheduleModal = ({ open, setVisible, setSchedule }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState(null);
    const [formValues, setFormValues] = React.useState<any>();
    const { globalOrg } = useReviewContext();
    const { currentUser, authKey } = useAuthContext();
    const formRef = React.useRef<FormInstance>();

    const handleOk = () => {
        formRef.current.submit();
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const insertSchedule = () => {
        setConfirmLoading(true);
        const scheduleData = {
            title: formValues?.title,
            date: formValues?.date,
            initialReviewDate: formValues?.date,
            // frequency: formValues?.description,
            orgId: globalOrg.id,
            active: false,
            paymentPlan: 'paymentPlan',
            createdBy: `${currentUser.firstName} ${currentUser.lastName}`
        }

        APP_URL.post('reviewschedule', scheduleData, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            setSchedule(res.data)

            let changeStatusData = {
                "reviewId": res.data.id,
                "frequency": formValues?.frequency,
                "startDate": res.data.date,
                "orgId": globalOrg.id
            }

            APP_URL.post('reviewschedule/changestatus', changeStatusData, {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then((res2) => {
                setVisible(false);
                message.success(`${res.data.title} has been created successfully`);
            }).catch(err2 => {
                console.log(err2.message);
                setConfirmLoading(false);
                message.error('Something went wrong adding the review schedule');
            })
        }).catch(err => {
            console.log(err.message);
            setConfirmLoading(false);
            message.error('Something went wrong adding the review schedule');
        })
    }

    const onFinishFailed = () => {
        message.error("Something went wrong adding the review schedule")
    }

    const validateMessages = {
        required: "'${name}' is required!",
    };

    const frequencyOptions = ['Monthly', 'Quarterly', 'Yearly'];

    return (
        <>
            <Modal
                destroyOnClose={true}
                width={"40%"}
                title="Create Review Schedule"
                open={open}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Organisation created"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Save
                    </Button>,
                ]}
            >
                <Form
                    ref={formRef}
                    name="basic"
                    onValuesChange={(prevValues, values) => { setFormValues(values) }}
                    validateMessages={validateMessages}
                    initialValues={{ remember: false }}
                    onFinish={insertSchedule}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="title"
                        rules={[{ required: true, }]}
                    >
                        <Input placeholder={'Quarterly Review'} onChange={(val) => (val.target.value)} className='modal-data-input'></Input>
                    </Form.Item>
                    <Form.Item
                        name="date"
                        rules={[{ required: true, }]}
                    >
                        <DatePicker placeholder='Start Date' value={startDate} onChange={d => setStartDate(d)} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="frequency"
                        rules={[{ required: true, }]}
                    >
                        <Select style={{ width: '100%' }} placeholder="Frequency"   >
                            {frequencyOptions.map((f, inx) => (
                                <Select.Option value={inx}>{f}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    )
}

export default CreateReviewScheduleModal;
