import React from 'react';
import './style.less';
import { Tag } from 'antd';
import Carousel from "react-multi-carousel";
import { Reports } from '../../../components'
import { GrDocumentText } from 'react-icons/gr';


export const Analytics = () => {
    const [currentReport, setCurrentReport] = React.useState(null);
    const [clickedItemInx, setClickedItemInx] = React.useState(0);


    const reportList = [
        { name: 'Outstanding Feedback Report', component: <Reports.CompletedStatusReport /> },
        { name: 'Review Summary Report', component: <Reports.ReviewSummaryReport /> },
        { name: 'Staff Reviews', component: <Reports.StaffReviews /> },
        { name: 'Managers Performance Rating', component: <Reports.ManagersPerformance /> },
        { name: 'Grows and Glows', component: <Reports.GrowsAndGlows /> }
    ]

    React.useEffect(() => {
        if (!currentReport) {
            setCurrentReport(reportList[0])
        }
    }, [currentReport])


    const setReportClick = (i) => {
        setClickedItemInx(i)
        setCurrentReport(reportList[i])
    }
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 767 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <main id="analytics">
            <div className='reports-list-row'>
                <Carousel
                    infinite
                    className='carousel-reports'
                    responsive={responsive}
                    removeArrowOnDeviceType={["tablet", "mobile", "notebook"]}
                >
                    {reportList.map((report, i) => (
                        <div onClick={() => setReportClick(i)} className={`tags-div`}>
                            <Tag icon={<GrDocumentText style={{ marginRight: '4px', fontSize: '1.6rem' }} />} className={`report-tag ${clickedItemInx === i ? "selected-report" : ""}`}>{report.name}</Tag>
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className='report-view'>
                {currentReport && currentReport.component}

            </div>

        </main>
    );
};
export declare type FixedType = 'left' | 'center' | 'right';
