import React from 'react';
import { Drawer, Table, Button, Popconfirm, Checkbox, message } from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { Modals, Spinners } from '../../components'
import { MdDelete } from 'react-icons/md';

type DrawerProps = {
    open: boolean
    setVisible: Function
    employee: any
}

const ManageDeliverablesDrawer = ({ open, setVisible, employee }: DrawerProps) => {
    const [currentDeliverable, setCurrentDeliverable] = React.useState(null)
    const [kpi, setKpi] = React.useState(null)
    const [kpiModalVisible, setKpiModalVisible] = React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)
    const [deliverables, setDeliverables] = React.useState([])
    const { authKey } = useAuthContext();

    const handleDeleteItemOk =async (id)=>{
        APP_URL.delete(`Delivarable/${id}`, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            message.success("KPI deleted successfully");
            getDeliverables();
        }).catch((err: AxiosError) => {
            message.error("Something went wrong deleting the KPI")
        })
    }

    const onClose = () => {
        setVisible(false)
    }

    React.useEffect(() => {
        if (employee) {
            getDeliverables()
        }
    }, [employee])

    React.useEffect(() => {
        if (currentDeliverable) {
            getDeliverables()
        }
    }, [currentDeliverable])

    const getDeliverables = () => {
        setTableLoading(true)
        APP_URL.get(`delivarable/employee/${employee.id}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDeliverables(res.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setTableLoading(false)
            })
    }
    const approveDeliverable = (deliverable, approved) => {
        setTableLoading(true)
        deliverable.approved = !approved
        APP_URL.put(`delivarable/${deliverable.id}`, deliverable,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                getDeliverables()
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setTableLoading(false)
            })
    }

    const kpiColumns = [
        {
            title: <div className="user-table-head">KPI</div>,
            dataIndex: 'title',
            key: 'title',
        },

        {
            title: <div className="user-table-head">Approve</div>,
            dataIndex: 'approved',
            key: 'approved',
            render: (val, record) =>
                <div>
                    <Checkbox onChange={() => approveDeliverable(record, val)} checked={val} > Approved </Checkbox>
                </div>
        },

        {
            title: <div className="user-table-head">Actions</div>,
            dataIndex: 'id',
            key: 'id',
            render: (test, row) =>
                <div>
                    <Button onClick={() => { setKpi(row); setKpiModalVisible(true); }} className="update-btn">Update</Button>
                    <Popconfirm
                        title="Remove KPI?"
                        onConfirm={(e) => { e.stopPropagation(); handleDeleteItemOk(row.id) }}
                        onCancel={(e) => { e.stopPropagation(); message.warn("Delete KPI operation cancelled");}} 
                    >
                        <Button onClick={(e) => e.stopPropagation()} className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },

    ];
    return (
        <>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                open={open}
                width={'50%'}
                className='employee-drawer'
            >
                <div className='deliverable-flex del-drawer'>
                    <span>{employee?.firstname}'s' KPIs</span>
                    <Button onClick={() => { setKpi(null); setKpiModalVisible(true) }} className="primary">Add New KPI</Button>
                </div>

                <Table
                    className='review-table'
                    // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                    rowKey="id"
                    columns={kpiColumns}
                    dataSource={deliverables}
                    loading={tableLoading}
                />

                <Modals.KPIModal kpi={kpi} open={kpiModalVisible} empId={employee?.id} approved={true} setDeliverable={setCurrentDeliverable} setVisible={setKpiModalVisible} />

            </Drawer>
        </>
    )
}

export default ManageDeliverablesDrawer;