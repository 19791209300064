import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { useAuthContext } from './context/AuthContext';
import { useReportContext } from './context/ReportContext';
import { APP_URL } from './config/axios'
import Admin from './layout/Admin';
import Employee from './layout/Employee';
import PrintableReview from './components/Reports/PrintableReview'
import Landing from './layout/Landing';
import { Home as AdminHome } from './pages/admin-pages/home';
import { Home, } from './pages/employee-pages/home';
import ReactGA from 'react-ga';
import { Reviews } from './pages/employee-pages/reviews';
import { Reports } from './pages/employee-pages/reports';
import { Team } from './pages/employee-pages/team';
import { Analytics } from './pages/admin-pages/analytics';
import ReviewSchedule from './pages/admin-pages/review-schedule';
import { Survey } from './pages/admin-pages/survey';
import { UserManagement } from './pages/admin-pages/user-management';
import { Campaigns } from './pages/admin-pages/campaigns';
import { Profile } from './pages/employee-pages/profile';

ReactGA.initialize('UA-207973303-1');

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const PrivateRoute = ({ children }) => {
  const { isLogged, setIsLogged,authKey, setAuthKey, authenticateUser, currentUser } = useAuthContext();
  const { getCompletedStatus, completedStatusReport } = useReportContext();
  const navigate = useNavigate();
  React.useEffect(() => {

    if (authKey && authKey !== 'null') {
      setIsLogged(true);
      setAuthKey(authKey);

      if (completedStatusReport.length < 1) {
        getCompletedStatus(authKey);
      }
      // navigate('/employee/home');

      if (!currentUser) {
        APP_URL.get("userauth/authorize-user", {
          headers: { "Authorization": `Basic ${authKey}` }
        });
      }
    }
    else {
      console.log('User is not logged in', authKey, isLogged);
      navigate('/');
    }
  }, [authKey, currentUser, completedStatusReport, setIsLogged, setAuthKey, getCompletedStatus, isLogged, navigate]);

  

  return children;
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/join/:orgCode" element={<Landing />} />
        <Route path="/admin/*" element={ <PrivateRoute> <Admin /> </PrivateRoute> }>
          <Route path="home" element={<AdminHome />} />
          <Route path="review-schedule" element={<Analytics />} />
          <Route path="analytics" element={<ReviewSchedule />} />
          <Route path="survey-design" element={<Survey isCampaign={false} campaign={null} />} />
          <Route path="user-management" element={<UserManagement/>} />
          <Route path="campaigns" element={<Campaigns />} />
        </Route>
        <Route path="/employee/*" element={<PrivateRoute> <Employee /> </PrivateRoute>}>
          <Route path="home" element={<Home />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="reports" element={<Reports />} />
          <Route path="team" element={<Team />} />
          <Route path="profile" element={<Profile />} />
        </Route>

        <Route path="/review/:id" element={<PrintableReview />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;