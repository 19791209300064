import React from 'react';
import { User } from '../models/User';
import { APP_URL } from '../config/axios';

interface IAuthContext {
  isLogged?: boolean;
  setIsLogged?: any;
  authKey?: any;
  setAuthKey?: any;
  authenticateUser?(authKey, user): any
  currentUser?: User;
  setCurrentUser?: any;
}

export const AuthContext = React.createContext<IAuthContext>({});

export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const [isLogged, setIsLogged] = React.useState(false);
  const [authKey, setAuthKey] = React.useState(localStorage.getItem('authKey'));
  const [currentUser, setCurrentUser] = React.useState(null);

    // Check localStorage on mount
    React.useEffect(() => {
      const storedAuthKey = localStorage.getItem('authKey');
      if (storedAuthKey && storedAuthKey !== 'null') {
          APP_URL.get("userauth/authorize-user", {
            headers: { "Authorization": `Basic ${storedAuthKey}` }
          })
          .then(res => {
            if (res.status === 200) {
              setCurrentUser(res.data);
            }
          })
          .finally(() => {
            setIsLogged(true);
          });
        setAuthKey(storedAuthKey);
        setIsLogged(true);
        // console.log('User is logged in', storedAuthKey);
        
        // Optionally fetch user data here using the stored auth key
      }
    }, []);

  const authenticateUser = (authKey: string, user: User) => {
    localStorage.setItem('authKey', authKey)
    setAuthKey(authKey)
    setIsLogged(true)
    setCurrentUser(user)
  }


  return (
    <AuthContext.Provider value={{ isLogged, setIsLogged, authenticateUser, authKey, setAuthKey, currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
