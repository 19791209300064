import React from 'react';
import { Modal, Button, Input, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type ModalProps = {
    open: boolean,
    setVisible: Function,
    setUserType: Function,
    userType: any
}

const UserTypeModal = ({ open, setVisible, setUserType, userType }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [userTypeName, setUserTypeName] = React.useState('');
    const [typeDesc, setTypeDesc] = React.useState('');
    const { authKey } = useAuthContext();

    React.useEffect(() => {
        if (userType !== null) {
            setUserTypeName(userType.typeName)
            setTypeDesc(userType.description)

        }
        else {
            setUserTypeName('')
            setTypeDesc('')
        }

    }, [userType])

    const postUserType = () => {
        setConfirmLoading(true);

        let userTypeData = {
            typeName: userTypeName,
            description: typeDesc,
        }



        if (userTypeName.length < 1) {
            message.warning("please enter usertype name")
        }
        else {
            APP_URL.post("usertype", userTypeData, {
                headers:
                    { "Authorization": "Basic " + `${authKey}` }
            }).then(res => {
                setUserType(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Usertype added successfully")
                setUserTypeName("")
                setTypeDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("Something went wrong adding the usertype")
            })
        }
    }

    const putUserType = () => {
        setConfirmLoading(true);

        userType.typeName = userTypeName
        userType.description = typeDesc

        if (userTypeName.length < 1) {
            message.warning("please enter usertype name")
        }
        else {
            APP_URL.put(`usertype/${userType.id}`, userType, {
                headers:
                    { "Authorization": "Basic " + `${authKey}` }
            }).then(res => {
                setUserType(null)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Usertype updated successfully")
                setUserTypeName("")
                setTypeDesc("")
            }).catch((err: AxiosError) => {
                console.log(err.message)
                message.error("Something went wrong updating the usertype")
            })
        }
    }

    const handleOk = () => {

        if (userType === null) {
            postUserType()
        }
        else {
            putUserType()
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    return (
        <>
            <Modal
                title="Create a user type"
                open={open}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Usertype added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>Type Name:</div> <Input className='modal-data-input' value={userTypeName} onChange={(val) => setUserTypeName(val.target.value)} placeholder="Admin"></Input>
                    <div>Description:</div> <Input className='modal-data-input' value={typeDesc} onChange={(val) => setTypeDesc(val.target.value)} placeholder="Can add and edit surveys"></Input>
                </div>

            </Modal>
        </>
    )
}

export default UserTypeModal;
