import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { employee_pages } from '../pages/employee-pages';
import { Layouts } from '../utils/enums';
import { Layout, Tag } from 'antd';
import { Headers, Siders } from '../components';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai'
import { Home } from '../pages/employee-pages/home';

interface RouteConfig {
  layout: Layouts;
  path: string;
  exact: boolean;
  component: React.ComponentType;
}

const Employee = () => {
    const { Content } = Layout;
    const [collapsed, setCollapsed] = React.useState(true);

    const getRoutes = (defaultRoutes: RouteConfig[]) =>
        defaultRoutes.map(prop => {
            if (prop.layout === Layouts.Employeee) {
                const Component = prop.component;
                return (
                    <Route
                        key={prop.path}
                        path={prop.layout + prop.path}
                        element={<Component />}
                    />
                );
            }
            return null;
        });

    return (
        <main id="employee">
            <Headers.Mobile routes={employee_pages} />
            <Layout>
                <Siders.Main layout={Layouts.Employeee} collapsed={collapsed} routes={employee_pages} />
                <Content className="content">
                    <div style={{ display: 'flex', backgroundColor: '#fff' }}>
                        {React.createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
                            className: 'menu-trigger',
                            onClick: () => setCollapsed(!collapsed)
                        })}
                        <Headers.Main />
                    </div>
                    <Outlet />
                </Content>
            </Layout>
        </main>
    );
};

export default Employee;