import React from 'react';
import { Modal, Button, Input, Select, DatePicker, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import moment from 'moment'

type ModalProps = {
    open: boolean
    setVisible: Function
    setEmployee: Function
    employee: any
    employees: any[]
    roles: any[]
    departments: any[]
}

const EmployeeModal = ({ open, setVisible, setEmployee, employee, roles, employees, departments }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [managerId, setManagerId] = React.useState(null);
    const [roleId, setRoleId] = React.useState(null);
    const [deptId, setDeptId] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const { authKey } = useAuthContext();
    const { globalOrg } = useReviewContext();

    const { Option } = Select

    const postEmployee = () => {

        setConfirmLoading(true);
        let date;
        if (startDate === null) {
            date = moment();
        } else {
            date = startDate
        }

        let employeeData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            managerId: managerId,
            roleId: roleId,
            startDate: date,
            active: false,
            deptId: deptId,
            orgId: globalOrg.id,
            userTypeId: 1
        }

        if (firstName.length < 1) {
            message.warning("Please enter a first name")
        }
        else if (lastName.length < 1) {
            message.warning("Please enter a last name")
        }
        else if (email.length < 1) {
            message.warning("Please enter an email address")
        }
        else if (roleId === null) {
            message.warning("Please select a role")
        }

        else {
            APP_URL.post("employee", employeeData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setEmployee(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Employee added successfully")
                setFirstName("")
                setLastName("")
                setEmail("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the employee")
                console.log(err.message)
            })
        }
    }

    const putEmployee = () => {
        setConfirmLoading(true);

        employee.firstName = firstName
        employee.lastName = lastName
        employee.email = email
        employee.roleId = roleId
        employee.managerId = managerId
        employee.startDate = startDate
        employee.deptId = deptId

        if (firstName.length < 1) {
            message.warning("Please enter a first name")
        }
        else if (lastName.length < 1) {
            message.warning("Please enter a last name")
        }
        else if (email.length < 1) {
            message.warning("Please enter a first name")
        }
        else if (roleId === null) {
            message.warning("Please enter a role")
        }
        else if (deptId === null) {
            message.warning("Please enter a department")
        }
        else {

            APP_URL.put(`employee/${employee.id}`, employee, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setEmployee(null)
                setVisible(false)
                setConfirmLoading(false);
                message.success("Employee updated successfully")
                setFirstName("")
                setLastName("")
                setEmail("")
            }).catch((err: AxiosError) => {
                message.error("Something went wrong updating the employee")
                console.log(err.message)
            })
        }
    }

    React.useEffect(() => {
        // console.log(employee)
        if (employee !== null) {

            setFirstName(employee.firstname)
            setLastName(employee.lastname)
            setEmail(employee.email)
            setManagerId(employee.managerId)
            setRoleId(employee.roleId)
            setStartDate(employee.startDate)
            setDeptId(employee.deptId)
            console.log(employee.deptId)

        }
        else {
            setFirstName("")
            setLastName("")
            setEmail("")
            setManagerId(null)
            setRoleId(null)
            setDeptId(null)
            setStartDate(null)
        }
    }, [employee])

    const handleOk = () => {

        if (employee === null) {
            postEmployee()
        } else {
            putEmployee()
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };


    const handleManagerIdSelect = val => {
        setManagerId(val)
    }

    const handleRoleSelect = (val) => {
        setRoleId(val)
    }
    const handleDeptSelect = (val) => {
        setDeptId(val)
    }

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString)
    }

    const disableEmpItem = (emp) => {
        if (employee && employee.id === emp.id) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <Modal
                title="Create an Emloyee"
                open={open}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Tribe added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>
                        <div>Role:</div>
                        <Select onChange={handleRoleSelect} value={roleId} className='modal-select'>
                            {roles.map((r, i) => (
                                <Option key={i} value={r.id}><h6 className="role">{r.roleName} </h6> </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div>Departments:</div>
                        <Select onChange={handleDeptSelect} value={deptId} className='modal-select'>
                            {departments.map((d, i) => (
                                <Option key={i} value={d.id}><h6 className="role">{d.deptName} </h6> </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div>Manager:</div>
                        <Select onChange={handleManagerIdSelect} value={managerId} className='modal-select'>
                            {employees.map((emp, i) => (
                                <Option key={i} disabled={disableEmpItem(emp)} value={emp.id}><h6 className="role">{emp.firstname} {emp.lastname}</h6> </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div>Start Date:</div>
                        <DatePicker className='modal-select' value={startDate !== null ? moment(startDate) : moment()} onChange={handleDateChange} />
                    </div>
                    <div>First Name:</div> <Input className='modal-data-input' value={firstName} onChange={(val) => setFirstName(val.target.value)} placeholder="John"></Input>
                    <div>Last Name:</div> <Input className='modal-data-input' value={lastName} onChange={(val) => setLastName(val.target.value)} placeholder="Doe"></Input>
                    <div>Email:</div> <Input type={'email'} className='modal-data-input' value={email} onChange={(val) => setEmail(val.target.value)} placeholder="johndoe@gmail.com"></Input>
                </div>
            </Modal>
        </>
    )
}

export default EmployeeModal;
