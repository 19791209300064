import React from 'react';
import { useReviewContext } from '../../../context/ReviewsContext';
import { useAuthContext } from '../../../context/AuthContext';
import { APP_URL } from '../../../config/axios';
import './style.less';
import { Button, Table, Checkbox, Popconfirm, message} from 'antd';
import {Buttons, Modals, Drawers} from '../../../components';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';

export const Campaigns = () =>{
    const [campaigns, setCampaigns] = React.useState([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState([]);
    const [campainDrawerVisible, setCampaignDrawerVisible] = React.useState(false);
    const [createCampaignModalVisible, setCreateCampaignModalVisible] = React.useState(false);
    const { globalOrg } = useReviewContext();
    const { authKey } = useAuthContext();

    const getCampaigns= () => {
        APP_URL.get(`campaign/org/${globalOrg.id}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            setCampaigns(res.data)
        }).catch(err => {
            console.log(err.message);
        })
    }

    React.useEffect(() => {
        APP_URL.get(`campaign/org/${globalOrg.id}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            setCampaigns(res.data)
        }).catch(err => {
            console.log(err.message);
        })
    }, [authKey])

    const openCampaign = (campaign) =>{
        console.log(campaign)
        setSelectedCampaign(campaign);
        setCampaignDrawerVisible(true);
    }

    const handleUpdateCampaignActive = (campaignId) =>{
        APP_URL.put(`campaign/updateActive//${campaignId}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            message.success("Active status updated successfully");
            getCampaigns();
        }).catch(err => {
            message.error("Something went wrong changing Active status");
        })
    }

    const handleDeleteCampaign = (campaignId) =>{
        APP_URL.delete(`campaign/${campaignId}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        }).then((res) => {
            message.success("Campaign deleted successfully");
            getCampaigns();
        }).catch(err => {
            message.error("Something went wrong deleting the Campaign");
        })
    }

    const campaignColumns = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <div className="user-table-head">Active</div>,
            dataIndex: 'active',
            key: 'active',//
            render: (test, campaign) =>
            <div>
                <Checkbox checked={test} onClick={()=>handleUpdateCampaignActive(campaign.id)}> Active </Checkbox>
            </div>
        },
        {
            title: <div className="user-table-head">Date Created</div>,
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            render: (val) => (moment(val).format("DD MMM YYYY"))
        },
        {
            title: <div className="user-table-head">View</div>,
            dataIndex: 'update',
            key: 'update',
            render: (val, campaign) =>
                <div>
                    <Button className="update-btn" onClick={()=>openCampaign(campaign)}>View</Button>
                    <Popconfirm
                        onConfirm={(e) => { e.stopPropagation(); handleDeleteCampaign(campaign.id) }}
                        onCancel={(e) => { e.stopPropagation(); message.warn("Delete Campaign operation cancelled");}} 
                        title="Delete campaign?"
                    >
                        <Button className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },
    ]

    return(
        <main id="campaigns">
            <div>
                <div onClick={()=>setCreateCampaignModalVisible(true)}>
                    <Buttons.Primary text="Create Campaign"/>
                </div>
                
                <Table className='user-table' dataSource={campaigns} rowKey="id" columns={campaignColumns} />    

                <Modals.CreateCampaignModal open={createCampaignModalVisible} setVisible={setCreateCampaignModalVisible}/>

                <Drawers.CampaignsDrawer open={campainDrawerVisible} setVisible={setCampaignDrawerVisible} campaign={selectedCampaign} />
            </div>
        </main>
    );
}

export declare type FixedType = 'left' | 'center' | 'right';