import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import { admin_pages } from '../pages/admin-pages';
import { Layouts } from '../utils/enums';
import { Layout } from 'antd';
import { Headers, Siders } from '../components';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai'


interface RouteConfig {
  layout: Layouts;
  path: string;
  exact: boolean;
  component: React.ComponentType;
}

const Admin = () => {
  const { Content } = Layout;
  const [collapsed, setCollapsed] = React.useState(true);

  const getRoutes = (defaultRoutes: RouteConfig[]) =>
    defaultRoutes.map(prop => {
      if (prop.layout === Layouts.Admin) {
        const Component = prop.component;
        return (
          <Route
            key={prop.path}
            path={prop.path}
            element={<Component />}
          />
        );
      }
      return null;
    });

  return (
    <main id="admin">
      <Headers.Mobile routes={admin_pages} />
      <Layout>
        <Siders.Main collapsed={collapsed} routes={admin_pages} />
        <Content className="content">
          <div style={{ display: 'flex', backgroundColor: '#fff' }}>
            {React.createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
              className: 'menu-trigger',
              onClick: () => setCollapsed(!collapsed)
            })}
            <Headers.Main />
          </div>
          <Outlet />
        </Content>
      </Layout>
    </main>
  );
};

export default Admin;
