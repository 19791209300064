import React from 'react';
import { Modal, Button, Input, Select, Checkbox, message } from 'antd';
import { APP_URL } from '../../config/axios'
import './style.less'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';

type ModalProps = {
    open: boolean,
    setVisible: Function,
    setSurvey: Function,
    reviewSchedules: any[]
}

const SurveyModal = ({ open, setVisible, setSurvey, reviewSchedules, }: ModalProps) => {
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [selfReview, setSelfReview] = React.useState(false);
    const [surveyTitle, setSurveyTitle] = React.useState('');
    const [formType, setFormType] = React.useState('manager');
    const [reviewScheduleId, setReviewScheduleId] = React.useState(null);
    const { authKey, currentUser } = useAuthContext();
    const { globalOrg } = useReviewContext();

    const { Option } = Select

    const postSurvey = () => {
        setConfirmLoading(true);

        let surveyData = {
            title: surveyTitle,
            formType: formType,
            reviewScheduleId: reviewScheduleId,
            createdBy: `${currentUser.firstName} ${currentUser.lastName}`,
            selfReview: selfReview,
            active: true,
            orgId: globalOrg.id
        }

        if (reviewScheduleId === null) {
            message.warning("Please select a Review Schedule")
        }
        else if (formType.length < 1) {
            message.warning("Please select a survey type")
        }
        else if (surveyTitle.length < 1) {
            message.warning("Please enter a title")
        }
        else {
            APP_URL.post("survey", surveyData, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setSurvey(res.data)
                setVisible(false);
                setConfirmLoading(false);
                message.success("Survey added successfully")
                setSurveyTitle("")

            }).catch((err: AxiosError) => {
                message.error("Something went wrong adding the survey")
                console.log(err.message)
            })
        }
    }

    const handleOk = () => {
        postSurvey()
    };

    const toggleSelfReview = (e) => {
        setSelfReview(e.target.checked)
    }

    const handleCancel = () => {
        setVisible(false);
        setConfirmLoading(false)
    };

    const handleTypeSelect = (val) => {
        setFormType(val)
    }
    const handleScheduleSelect = (val) => {
        setReviewScheduleId(val)
    }


    return (
        <>
            <Modal
                title="Create a new Survey"
                open={open}
                className="data-input-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={"Survey added"}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div>
                    <div>
                        <div>Review Schedule:</div>
                        <Select onChange={handleScheduleSelect} value={reviewScheduleId} className='modal-select'>
                            {reviewSchedules.map((rs, i) => (
                                <Option key={i} value={rs.id}><h6 className="role">{rs.title} </h6> </Option>
                            ))}
                        </Select>
                        <div>Type:</div>
                        <Select onChange={handleTypeSelect} value={formType} className='modal-select'>
                            <Option value={'manager'}><h6 className="role">Manager</h6> </Option>
                            <Option value={'employee'}><h6 className="role">Employee</h6> </Option>
                        </Select>
                        <Checkbox className={'self-rev-cb'} onChange={toggleSelfReview}>Self Review?</Checkbox>
                    </div>

                    <div>Title:</div> <Input value={surveyTitle} className='modal-data-input' onChange={(val) => setSurveyTitle(val.target.value)} placeholder="January 2020 Survey"></Input>
                </div>
            </Modal>
        </>
    )
}

export default SurveyModal;
