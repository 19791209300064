import React from 'react';
import { Avatar, message, Rate, Table, Button, Popconfirm, Tooltip, Input } from 'antd'
import { Reviews } from '../reviews'
import moment from 'moment'
import rating_icon from '../../../assets/icons/rating-icon.svg'
import bag_icon from '../../../assets/icons/bag-icon.svg'
import user from '../../../assets/icons/user.png'
import calendar_icon from '../../../assets/icons/calendar-icon.svg'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../../context/AuthContext';
import { useReviewContext } from '../../../context/ReviewsContext';
import { APP_URL } from '../../../config/axios'
import { Modals, Spinners } from '../../../components'

import './style.less';
import { Reports } from '../reports';
import { useNavigate, useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

declare const window: any;

export const Home = () => {
    const [loading, setLoading] = React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)
    const [manager, setManager] = React.useState(null)
    const [roles, setRoles] = React.useState([])
    const { authKey, currentUser } = useAuthContext();
    const { globalUserEmp, setGlobalUserEmp, globalOrg, setGlobalShedules } = useReviewContext();
    const [reviewSchedule, setReviewSchedule] = React.useState([]);
    const [nextReviewDate, setNextReviewDate] = React.useState(null);
    const [questionResponses, setQuestionResponses] = React.useState([]);
    const [deliverables, setDeliverables] = React.useState([]);
    const [lifetimeRating, setLifetimeRating] = React.useState(0);
    const [kpiModalVisible, setKpiModalVisible] = React.useState(false)
    const [approved, setApproved] = React.useState(false)
    const [currentDeliverable, setCurrentDeliverable] = React.useState(null)
    const [kpi, setKpi] = React.useState(null)
    const [employees, setEmployees] = React.useState<any[]>([]);
    const [glows, setGlows] = React.useState([]);
    const [grows, setGrows] = React.useState([]);
    const [organizations, setOrganizations] = React.useState<any[]>([]);
    const { orgCode } = useParams<any>();
    const navigate = useNavigate();
    const { TextArea } = Input;

    const getDisplayPic = (emp) => {
        if (emp?.profilePic !== null) { return String(emp?.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp?.firstname + ' ' + emp?.lastname}&background=59394A&color=fff&size=256` }
    }

    const handleDeleteItemOk = async (id) => {
        APP_URL.delete(`Delivarable/${id}`, {
            headers:
                { "Authorization": `Basic ${authKey}` }
        }).then(res => {
            message.success("KPI deleted successfully");
            getDeliverables();
        }).catch((err: AxiosError) => {
            message.error("Something went wrong deleting the KPI")
        })
    }

    const getGlows = React.useCallback(() => {
        APP_URL.get(`employee/glows/${globalUserEmp.id}`, { headers: { "Authorization": `Basic ${authKey}` } })
        .then(res => {
                setGlows(res.data)
                console.log("glows", res.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey, globalUserEmp]);

    const getGrows = React.useCallback(() => {
        APP_URL.get(`employee/grows/${globalUserEmp.id}`, { headers: { "Authorization": `Basic ${authKey}` } })
        .then(res => {
                setGrows(res.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey, globalUserEmp]);

    const kpiColumns = [
        {
            title: <div className="user-table-head">KPI</div>,
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: <div className="user-table-head">Created by</div>,
            dataIndex: 'creatorId',
            key: 'creatorId',
            render: (text, record) => (
                <div>

                    <Avatar className='table-avatar-mobile' src={getDisplayPic(employees.length > 0 && employees.filter(r => r.id === text)[0])} />
                    <span style={{ marginLeft: 16 }}>{employees.length > 0 && employees.filter(r => r.id === text)[0]?.firstname}&nbsp;
                        {employees.length > 0 && employees.filter(r => r.id === text)[0]?.lastname}</span>
                </div>)
        },
        {
            title: <div className="user-table-head">Approved</div>,
            dataIndex: 'approved',
            width: 150,
            key: 'approved',
            render: (approved) =>
                <>
                    {
                        Boolean(approved) === true ?
                            <AiOutlineCheck className='check-icon' />
                            :
                            <Tooltip placement='bottom' title={`This deliverable needs to be approved by your manager`}>
                                <AiOutlineClose className="close-icon" />
                            </Tooltip>

                    }
                </>
        },
        {
            title: <div className="user-table-head">Actions</div>,
            dataIndex: 'id',
            key: 'id',
            render: (test, row) =>
                <div>
                    <Button onClick={() => { setKpi(row); setKpiModalVisible(true); }} className="update-btn">Update</Button>
                    <Popconfirm
                        title="Remove KPI?"
                        onConfirm={(e) => { e.stopPropagation(); handleDeleteItemOk(row.id) }}
                        onCancel={(e) => { e.stopPropagation(); message.warn("Delete KPI operation cancelled"); }}
                    >
                        <Button onClick={(e) => e.stopPropagation()} className="delete-btn" icon={<MdDelete />} ></Button>
                    </Popconfirm>
                </div>
        },

    ];

    const init_intercom = () => {
        if (!!window.Intercom) {
            window.Intercom("boot", {
                app_id: "hw17kn9z",
            });
        }
    }

   

    const getManager = React.useCallback(() => {
        APP_URL.get(`userauth/${currentUser.id}/employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                APP_URL.get(`employee/${user.data.employees[0].id}`,
                    {
                        headers: { "Authorization": `Basic ${authKey}` }
                    }).then(employeeRes => {
                        setManager(employeeRes.data.manager)
                        setGlobalUserEmp(employeeRes.data)
                    })

            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey, currentUser, setGlobalUserEmp])

    const getUserEmp = React.useCallback(() => {
        APP_URL.get(`userauth/${currentUser.id}/employee`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(user => {
                setGlobalUserEmp(user.data.employees[0])
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                // setLoading(false)
            })
    }, [authKey, currentUser, setGlobalUserEmp])

    const getReviewSchedule = React.useCallback(() => {
        // setLoading(true)
        // console.log("called it")
        APP_URL.get(`reviewschedule/org/${globalOrg?.id}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setReviewSchedule(res.data)
                setGlobalShedules(res.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setLoading(false)
            })
    }, [authKey, globalOrg?.id, setGlobalShedules])
    const getRoles = React.useCallback(() => {
        if (roles.length === 0) {
            APP_URL.get(`role/org/${globalOrg?.id}`,
                {
                    headers:
                        { "Authorization": `Basic ${authKey}` }
                }).then(res => {
                    if (res.status === 200) {
                        setRoles(res.data)

                    }
                })
                .catch((err: AxiosError) => {
                    if (err.response) {
                        console.log(err.message)
                    }
                }).finally(() => {
                })
        }
    }, [authKey, globalOrg, roles.length])

    const getDeliverables = React.useCallback(() => {
        setTableLoading(true)
        APP_URL.get(`delivarable/employee/${globalUserEmp?.id}`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(res => {
                setDeliverables(res.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            }).finally(() => {
                setTableLoading(false)
            })
    }, [authKey, globalUserEmp])

    const getQuestionResponses = React.useCallback(() => {
        APP_URL.get(`questionResponse`,
            {
                headers: { "Authorization": `Basic ${authKey}` }
            }).then(qs => {
                setQuestionResponses(qs.data)
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
    }, [authKey])

    const getEmployees = React.useCallback(() => {
        APP_URL.get(`employee/org/${globalOrg.id}`, {
            headers: { "Authorization": `Basic ${authKey}` }
        })
            .then((res) => {
                setEmployees(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }, [authKey, globalOrg]);


    React.useEffect(() => {
        init_intercom()
        if (currentUser && globalUserEmp === null) {
            getUserEmp()
           
        }

        if (currentUser !== null && globalOrg !== null && globalUserEmp !== null && authKey !== null) {
            // console.log(globalUserEmp)
            if (globalUserEmp?.managerId !== null) { setApproved(false) } else { setApproved(false) }

            if (globalUserEmp?.manager != null) { setManager(globalUserEmp.manager); } else { getManager(); }

            getEmployees()
            getReviewSchedule();
            getRoles();
            getQuestionResponses();
            getDeliverables();
            getGlows();
            getGrows();
        }
    }, [currentUser, globalOrg, authKey, globalUserEmp, getUserEmp, getGlows, getGrows, getEmployees, getReviewSchedule, getRoles, getQuestionResponses, getDeliverables, getManager])

    const getNextReview = () => {
        const _upcomingReviews = reviewSchedule.filter(x => (Date.parse(String(x.date)) - new Date().getTime()) >= 0);
        const _sortedUpcomingReviews = _upcomingReviews.sort(x => new Date().getTime() - Date.parse(String(x.date)));

        if (_sortedUpcomingReviews.length > 0) {
            setNextReviewDate(_sortedUpcomingReviews[0].date);
        } else {
            setNextReviewDate(null);
        }
    }

    React.useEffect(() => {
        if (reviewSchedule.length > 0 && reviewSchedule) {
            getNextReview();
        }
    }, [reviewSchedule])

    React.useEffect(() => {
        // console.log(currentDeliverable)
        if (deliverables.length > 0 && currentDeliverable) {
            getDeliverables();
        }
    }, [currentDeliverable])

    const getAvgLifetimeRating = () => {
        let _totalRating = 0;
        const _questionResponses = questionResponses.filter(x => (x.isOverallRating === true)
            && x.reviewedId === globalUserEmp.id && x.reviewerId !== globalUserEmp.id)

        _questionResponses.forEach((qs) => _totalRating += JSON.parse(qs.answers)[0]);
        if (_totalRating) {
            setLifetimeRating(_totalRating / _questionResponses.length)
        } else {
            setLifetimeRating(0)
        }
    }

    React.useEffect(() => {
        if (questionResponses.length > 0 && !!globalUserEmp) {
            getAvgLifetimeRating();
        }
    }, [questionResponses, globalUserEmp])


    const getOrganisations = () => {
        APP_URL.get("organization")
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    const createEmployees = (org) => {
        const _employee = employees.find(x => x.userAuthId === currentUser.id);
        if (!_employee) {
            const _employee = {
                "email": currentUser.email,
                "userAuthId": currentUser.id,
                "firstname": currentUser.firstName,
                "lastname": currentUser.lastName,
                "roleId": null,
                "profilePic": currentUser.profilePic,
                "active": true,
                "orgId": org.id,
                "managerId": null,
                "startDate": "2021-03-29T13:05:40.327Z",
                "deptId": null,
                "userTypeId": 1
            }

            APP_URL.post("employee", _employee, {
                headers: { "Authorization": `Basic ${authKey}` }
            })
                .then((res) => {
                    message.success(`You have now joined ${org.name}`);
                    navigate('/employee/home')
                })
                .catch((err) => {
                    console.log(err.message);
                    message.error('There was an error adding you to the organisation"');
                })
        }
    }



    React.useEffect(() => {
        getOrganisations();
    }, [orgCode])

    React.useEffect(() => {
        if (orgCode && employees.length > 0 && organizations.length > 0) {
            const organisation = organizations.find(x => x.organizationCode === orgCode);
            if (organisation) {
                createEmployees(organisation);
            }
            else {
                message.error("Organisation not found")
            }
        }
    }, [employees, organizations])

    return (
        <main id="home">
            <Spinners.CustomSpinner tip={"Please wait..."} spinning={loading}>

                <div>
                    <div className='home-card-flex'>
                        <div className='home-card'>
                            <div className='home-card-head'>Current Manager</div>
                            <div className='home-card-avatar'>
                                <Avatar src={manager && manager.profilePic} style={{ width: '60px', height: '60px' }} />
                                <div style={{ margin: '0 0 0px 5px' }}>
                                    <div className='home-card-name'>{manager ? (manager.firstname + " " + manager.lastname) : "N/A"}</div>
                                    <div className='home-card-role'>{manager && manager.email}</div>
                                    <div className='home-card-role'>{manager && String(roles.length > 0 && roles.filter(r => r.id === manager.roleId)[0]?.roleName)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='home-card'>
                            <div className='home-card-head'>Average Lifetime Rating</div>
                            <div className='home-card-info'>
                                <span className='big-num'>{lifetimeRating.toFixed(1)}</span> <span><img src={rating_icon} /></span>
                            </div>
                        </div>
                        <div className='home-card'>
                            <div className='home-card-head'>Next Review</div>
                            <div className='home-card-info'>
                                <span className='card-text'>{!!nextReviewDate ? moment(nextReviewDate).format('D MMM') : '---'}</span> <span><img src={calendar_icon} /></span>
                            </div>
                        </div>
                        <div className='home-card'>
                            <div className='home-card-head'>Employment Duration</div>
                            <div className='home-card-info'>
                                <span className='card-text'>{globalUserEmp && moment(globalUserEmp.startDate).fromNow(true)}</span> <span><img src={bag_icon} /></span>
                            </div>
                        </div>
                    </div>
                    <div className={['under-card'].join(' ')}>
                        <div>
                            <div className='con-glows'>Strengths:</div>
                            <div className='tags-flex'>
                                {glows.map(glow => <div key={glow} className='tags'>{glow.text}</div>)}
                            </div>
                        </div>
                        <div>
                            <div className='con-glows'>Limitations:</div>
                            <div className='tags-flex'>
                                {grows.map(grow => <div className='tags red'>{grow.text}</div>)}
                            </div>
                        </div>
                    </div>
                    {/* <div className='reviews-root'>
                        <div className='deliverable-flex'>
                            <span>Review Summary</span>
                            <Button onClick={() => { setKpi(null); setKpiModalVisible(true) }} className="primary">View More</Button>
                        </div>
                        <div className='rev-table-contain' >

                            <TextArea readOnly rows={4} />
                        </div>
                    </div> */}
                    <Reports isReportPage={false} />
                    <div className='reviews-root'>
                        <div className='deliverable-flex'>
                            <span>My Goals</span>
                            <Button onClick={() => { setKpi(null); setKpiModalVisible(true) }} className="primary">Add New KPI</Button>
                        </div>
                        <div className='rev-table-contain' >

                            <Table
                                className='review-table'
                                // rowClassName={(record, index) => isMobile ? (index % 2 === 0 ? 'table-row-light' : 'table-row-dark') : ''}
                                rowKey="id"
                                loading={tableLoading}
                                columns={kpiColumns}
                                dataSource={deliverables}
                            />
                        </div>
                    </div>
                    <Modals.KPIModal kpi={kpi} open={kpiModalVisible} approved={approved} empId={globalUserEmp?.id} setDeliverable={setCurrentDeliverable} setVisible={setKpiModalVisible} />
                </div>
            </Spinners.CustomSpinner>

        </main>
    )
};
