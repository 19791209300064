import React from 'react';
import { Modal, Button, message } from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import Avatar from 'antd/lib/avatar/avatar';
import { useAuthContext } from '../../context/AuthContext';
import color_logo from '../../assets/branding/organisations-logo.png'
import { AiFillCheckCircle } from 'react-icons/ai'

import './style.less'
import { useParams } from 'react-router-dom';
import { log } from 'util';

type ModalProps = {
    open: boolean,
    userData: any,
    setVisible: Function,
    history: any,
    setIsCreateModalVisible: Function
    setIsJoinModalVisible: Function
}

const RegisterModal = ({ open, setVisible, userData, setIsCreateModalVisible, setIsJoinModalVisible, history }: ModalProps) => {

    const { authenticateUser, currentUser, authKey } = useAuthContext();
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [organizations, setOrganizations] = React.useState<any>([]);

    const { orgCode } = useParams<any>()


    const handleCancel = () => {
        setVisible(false);
    };

    const handleOk = () => {
        if (orgCode) {
            const _organisation = organizations.find(x => x.organizationCode === orgCode);
            if (_organisation) {
                createUserAuth();
            }
            else {
                message.error(`Could not join organization with code.`)
            }
        }
    }

    const handleCreateOrganisation = () => {
        setIsCreateModalVisible(true);
        handleCancel();
    }

    const handleJoinOrganisation = () => {
        setIsJoinModalVisible(true);
        handleCancel();
    }

    const getOrganisations = () => {
        APP_URL.get("organization")
            .then((res) => {
                setOrganizations(res.data);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }

    const createUserAuth = () => {
        setConfirmLoading(true);

        APP_URL.post("userauth", userData)
            .then(res => {
                authenticateUser(btoa(`${userData.email}:${userData.authSystemId}`), res.data);
            })
            .catch((err) => {
                console.log(err.message)
                setConfirmLoading(false);
                message.error('There was an error joining your organisation');
            })
    }

    const createEmployee = () => {
        const _employee = {
            "email": currentUser.email,
            "userAuthId": currentUser.id,
            "firstname": currentUser.firstName,
            "lastname": currentUser.lastName,
            "roleId": null,
            "profilePic": currentUser.profilePic,
            "active": false,
            "orgId": organizations.find(x => x.organizationCode === orgCode)?.id,
            "managerId": null,
            "startDate": "2021-03-29T13:05:40.327Z",
            "deptId": null,
        }

        APP_URL.post("employee", _employee, {
            headers: { "Authorization": `Basic ${authKey}` }
        })
            .then((res) => {
                console.log(res.data);
                message.success(`You have now joined ${organizations.find(x => x.organizationCode === orgCode)?.name}`);
                setConfirmLoading(false);
                history.push('/employee/home')
            })
            .catch((err) => {
                console.log(err.message);
                setConfirmLoading(false);
                message.error('There was an error creating your organisation"');
            })
    }

    React.useEffect(() => {
        if (currentUser && authKey && confirmLoading) {
            createEmployee();
        }
    }, [currentUser, authKey])

    React.useEffect(() => {
        if (orgCode) {
            getOrganisations()
        }
    }, [orgCode])

    return (
        <>
            <Modal
                title="Create your account"
                open={open}
                className="register-modal"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={'Create your account'}
                footer={[
                    <Button key="back" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    orgCode && (
                        <Button key="submit" className="reg-btn" type="primary" loading={confirmLoading} onClick={handleOk}>
                            Continue
                        </Button>
                    ),
                ]}
            >
                <img className="color-logo" src={color_logo} />
                <div className='welcome-text' >Welcome, {userData.firstName} {userData.lastName}</div>

                <div className='avatar-flex'>
                    <Avatar className="modal-avatar" src={userData.profilePic} />
                </div>

                {!orgCode &&
                    <div className='welcome-options'>
                        <Button className='welcome-options-btn' onClick={handleCreateOrganisation}>Create Organisation</Button>
                        <Button className='welcome-options-btn' onClick={handleJoinOrganisation}>Join Organisation</Button>
                    </div>
                }

                <div className='welcome-email names-flex'>
                    <AiFillCheckCircle className="check" /> {userData.email}
                </div>

            </Modal>
        </>
    )
}

export default RegisterModal