import React from 'react';
import { Drawer, Table, Avatar, Tabs, Card } from 'antd';
import { APP_URL } from '../../config/axios'
import { AxiosError } from 'axios';
import { useAuthContext } from '../../context/AuthContext';

type DrawerProps = {
    open: boolean
    setVisible: Function
    employee: any
}

const EmployeeReviewDrawer = ({ open, setVisible, employee }: DrawerProps) => {
    const [currentEmployee, setCurrentEmployee] = React.useState(null);
    const [managerList, setManagerList] = React.useState([]);
    const { authKey } = useAuthContext();
    const { TabPane } = Tabs;

    const onClose = () => {
        setVisible(false)
    }

    React.useEffect(() => {
        console.log(employee)
        if (employee && employee.id) {
            APP_URL.get(`employee/${employee.id}`, {
                headers:
                    { "Authorization": `Basic ${authKey}` }
            }).then(res2 => {
                setCurrentEmployee(res2.data)
                setManagerList([])
                setManagerList(m => [...m, res2.data.manager])
            }).catch((err: AxiosError) => {
                if (err.response) {
                    console.log(err.message)
                }
            })
        }
    }, [employee])

    const getDisplayPic = (emp) => {
        if (emp.profilePic !== null) { return String(emp.profilePic); }
        else { return `https://ui-avatars.com/api/?name=${emp.firstname + ' ' + emp.lastname}&background=59394A&color=fff&size=256` }
    }

    const canReviewTableData = [
        {
            title: <div className="user-table-head">Name</div>,
            dataIndex: 'firstname',
            key: 'firstname',
            render: (text, record) => <div><Avatar size="small" className='table-avatar' src={getDisplayPic(record)} /> {text} {record.lastname}</div>
        },
        {
            title: <div className="user-table-head">Email</div>,
            dataIndex: 'email',
            key: 'email',
        },
    ];

    return (
        <>
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                open={open}
                width={'50%'}
                className='employee-drawer'
            >
                {currentEmployee &&
                    <div className="drawer-root">
                        <div className="employee-info" >
                            <Avatar className='drawer-avatar' src={getDisplayPic(currentEmployee)} />

                            <div className="drawer-emp-details" >
                                <div className='drawer-emp-name'>{currentEmployee.firstname} {currentEmployee.lastname}</div>
                                {/* <div className='drawer-emp-role'>
                                {renderMegaTribe(currentEmployee)} : {renderRole(currentEmployee)}
                            </div> */}
                            </div>
                        </div>
                        <hr className='drawer-line' />
                        <Tabs className='drawer-tab' defaultActiveKey="1">
                            <TabPane tab="Can Review" key="1">
                                <Table columns={canReviewTableData} dataSource={currentEmployee.inverseManager} />
                            </TabPane>
                            <TabPane tab="Reviewed By" key="2">
                                {currentEmployee.manager ?
                                    <Table columns={canReviewTableData} dataSource={managerList} />

                                    :
                                    <div>
                                        This user is not reviewed by anyone
                                </div>
                                }

                                {/* <Table columns={managerTableData} dataSource={[employee.manager]} /> */}
                            </TabPane>
                        </Tabs>

                    </div>
                }


            </Drawer>
        </>
    )
}

export default EmployeeReviewDrawer;