import { Avatar, Select, Badge, Popover, List, Button } from 'antd';
import React from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { useReviewContext } from '../../context/ReviewsContext';
import bell from '../../assets/icons/bell-filled.svg';
import { Drawers } from '../'
import { useNavigate } from 'react-router-dom';

import './style.less';
import { FileDoneOutlined } from '@ant-design/icons';
import { AiOutlineFileDone } from 'react-icons/ai';
import { APP_URL } from '../../config/axios';
import { AxiosError } from 'axios';

const Main = ( ) => {
  const [currentPage, setCurrentPage] = React.useState('')
  const { globalPendingReviews, globalUserEmp, setGlobalUserEmp, setGlobalOrg } = useReviewContext();
  const [selectedReview, setSelectedReview] = React.useState(null)
  const [revDrawerVisible, setRevDrawerVisible] = React.useState(false)
  const navigate = useNavigate();

  const { currentUser, authKey } = useAuthContext()
  const { Option } = Select;

  const handleSelect = (val) => {
    if (val === '/employee') { navigate('/employee/home') }
    if (val === '/admin') { navigate('/admin/home') }
  }

  const getDisplayPic = (emp) => {
    if (emp.profilePic !== null) { return String(emp.profilePic); }
    else { return `https://ui-avatars.com/api/?name=${emp.firstName + ' ' + emp.lastName}&background=59394A&color=fff&size=256` }
  }

  const displayReviewDrawer = (record) => {
    setSelectedReview(record)
    setRevDrawerVisible(true)
  }

  const text = <span className='notif-head'>Pending Reviews</span>;

  const getRevList = () => {
    if (globalPendingReviews && globalPendingReviews.length > 0) {
      return (globalPendingReviews.filter(revItem => revItem.completed === false))
    }
    else {
      return []
    }
  }



  const content = (
    <>
      {
        getRevList().length === 0 ?
          <div className='notification-review-comment'>
            <AiOutlineFileDone size={75} />
            <span style={{ marginLeft: '16px' }}>
              Thank you for completing your reviews.
              Please make sure to complete the review summary for each review.
              </span>
          </div>
          :
          <List

            itemLayout="horizontal"
            dataSource={globalPendingReviews && globalPendingReviews.length > 0 && globalPendingReviews.filter(revItem => revItem.completed === false)}
            renderItem={item => (
              <List.Item
                actions={[<Button onClick={() => displayReviewDrawer(item)} className='edit-q'> Review Now</Button>]}>
                <List.Item.Meta
                  avatar={<Avatar src={getDisplayPic(item)} />}
                  title={<span>{item.firstName + " " + item.lastName}</span>}
                  description={item.surveyTitle}
                />
              </List.Item>
            )}
          />
      }
    </>
  );

  const getUserEmp = () => {
    APP_URL.get(`userauth/${currentUser.id}/employee`, {
      headers: { "Authorization": `Basic ${authKey}` }
    }).then(user => {
      if (!globalUserEmp) {
        setGlobalUserEmp(user.data.employees[0])

        APP_URL.get(`organization/${user.data.employees[0].orgId}`, {
          headers: { "Authorization": `Basic ${authKey}` }
        }).then(org => {
          if (!globalUserEmp) {
            setGlobalOrg(org.data)
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.message)
          }
        })
      }
    }).catch((err) => {
      if (err.response) {
        console.log(err.message)
      }
    })
  }

  React.useEffect(() => {

    let url = window.location.pathname;

    if (url.includes("/admin")) {
      setCurrentPage("/admin")
    } else if (url.includes("/employee")) {
      setCurrentPage("/employee")
    }
  }, [])


  React.useEffect(() => {
    if (currentUser && authKey) {
      getUserEmp()
    }
  }, [currentUser, authKey,])

  return (
    <nav className="navbar-main">
      <div className="user-info-wrapper">
        <div className="user-name">
          <h5>{currentUser !== null && (currentUser.firstName + " " + currentUser.lastName)}</h5>
          {globalUserEmp !== null && globalUserEmp?.userTypeId === 2 &&
            <Select onChange={handleSelect} value={currentPage} className='usertype-select'>
              <Option key={1} value="/admin"><h6 className="role">admin </h6> </Option>
              <Option key={2} value="/employee"><h6 className="role">employee </h6> </Option>
            </Select>
          }
        </div>

        <Avatar
          size="large"
          style={{ background: '#f2f2f2' }}
          src={currentUser !== null && currentUser.profilePic}
        />
      </div>
      <Popover title={text} content={content} placement="bottom" trigger="click">
        <Badge count={getRevList().length === 0 ? 1 : getRevList().length} showZero>
          <img className='notif-icon' src={bell} />
        </Badge>
      </Popover>
      <Drawers.ReviewDrawer open={revDrawerVisible} selectedReview={selectedReview} reviewsList={getRevList()} setVisible={setRevDrawerVisible} />
    </nav>
  );
};

export default Main;
